//* This is generated. DO NOT TOUCH
/* eslint-disable */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { deleteAccountsPaymentsAutoPayConfiguration } from './endpoints/delete-accounts-payments-auto-pay-configuration/delete-accounts-payments-auto-pay-configuration'
import { deleteAccountsPayments } from './endpoints/delete-accounts-payments/delete-accounts-payments'
import { deleteCustomersFundingSourcesBankAccounts } from './endpoints/delete-customers-funding-sources-bank-accounts/delete-customers-funding-sources-bank-accounts'
import { deleteCustomersFundingSourcesDebitCards } from './endpoints/delete-customers-funding-sources-debit-cards/delete-customers-funding-sources-debit-cards'
import { getAccountsCardActivation } from './endpoints/get-accounts-card-activation/get-accounts-card-activation'
import { getAccountsCardLock } from './endpoints/get-accounts-card-lock/get-accounts-card-lock'
import { getAccountsCardPin } from './endpoints/get-accounts-card-pin/get-accounts-card-pin'
import { getAccountsCardPlasticDesigns } from './endpoints/get-accounts-card-plastic-designs/get-accounts-card-plastic-designs'
import { getAccountsCardReplacement } from './endpoints/get-accounts-card-replacement/get-accounts-card-replacement'
import { getAccountsCardShipment } from './endpoints/get-accounts-card-shipment/get-accounts-card-shipment'
import { getAccountsContacts } from './endpoints/get-accounts-contacts/get-accounts-contacts'
import { getAccountsOffersReinstatement } from './endpoints/get-accounts-offers-reinstatement/get-accounts-offers-reinstatement'
import { getAccountsPaymentsAutoPayConfiguration } from './endpoints/get-accounts-payments-auto-pay-configuration/get-accounts-payments-auto-pay-configuration'
import { getAccountsPaymentsHistory } from './endpoints/get-accounts-payments-history/get-accounts-payments-history'
import { getAccountsPaymentsPending } from './endpoints/get-accounts-payments-pending/get-accounts-payments-pending'
import { getAccountsRewardsActivity } from './endpoints/get-accounts-rewards-activity/get-accounts-rewards-activity'
import { getAccountsRewards } from './endpoints/get-accounts-rewards/get-accounts-rewards'
import { getBanks } from './endpoints/get-banks/get-banks'
import { getCustomersAccounts } from './endpoints/get-customers-accounts/get-customers-accounts'
import { getCustomersFundingSourcesBankAccounts } from './endpoints/get-customers-funding-sources-bank-accounts/get-customers-funding-sources-bank-accounts'
import { getCustomersFundingSourcesDebitCardsAuthToken } from './endpoints/get-customers-funding-sources-debit-cards-auth-token/get-customers-funding-sources-debit-cards-auth-token'
import { getCustomersFundingSourcesDebitCards } from './endpoints/get-customers-funding-sources-debit-cards/get-customers-funding-sources-debit-cards'
import { getCustomersIncome } from './endpoints/get-customers-income/get-customers-income'
import { getCustomersOffersAdditionalAccount } from './endpoints/get-customers-offers-additional-account/get-customers-offers-additional-account'
import { getIdpSecurityQuestions } from './endpoints/get-idp-security-questions/get-idp-security-questions'
import { getInvolvementsAddresses } from './endpoints/get-involvements-addresses/get-involvements-addresses'
import { getInvolvementsEmails } from './endpoints/get-involvements-emails/get-involvements-emails'
import { getInvolvementsPhones } from './endpoints/get-involvements-phones/get-involvements-phones'
import { postAccountsCardActivation } from './endpoints/post-accounts-card-activation/post-accounts-card-activation'
import { postAccountsCardReplacement } from './endpoints/post-accounts-card-replacement/post-accounts-card-replacement'
import { postAccountsPaymentsAutoPayConfiguration } from './endpoints/post-accounts-payments-auto-pay-configuration/post-accounts-payments-auto-pay-configuration'
import { postCustomersFundingSourcesBankAccountsMicroDeposits } from './endpoints/post-customers-funding-sources-bank-accounts-micro-deposits/post-customers-funding-sources-bank-accounts-micro-deposits'
import { postCustomersFundingSourcesBankAccountsYodleeVerification } from './endpoints/post-customers-funding-sources-bank-accounts-yodlee-verification/post-customers-funding-sources-bank-accounts-yodlee-verification'
import { postCustomersFundingSourcesBankAccounts } from './endpoints/post-customers-funding-sources-bank-accounts/post-customers-funding-sources-bank-accounts'
import { postCustomersFundingSourcesDebitCards } from './endpoints/post-customers-funding-sources-debit-cards/post-customers-funding-sources-debit-cards'
import { putAccountsAddresses } from './endpoints/put-accounts-addresses/put-accounts-addresses'
import { putAccountsCardLock } from './endpoints/put-accounts-card-lock/put-accounts-card-lock'
import { putAccountsCardPin } from './endpoints/put-accounts-card-pin/put-accounts-card-pin'
import { putAccountsCardPlasticDesigns } from './endpoints/put-accounts-card-plastic-designs/put-accounts-card-plastic-designs'
import { putAccountsEmails } from './endpoints/put-accounts-emails/put-accounts-emails'
import { putAccountsPaymentsAutoPayConfiguration } from './endpoints/put-accounts-payments-auto-pay-configuration/put-accounts-payments-auto-pay-configuration'
import { putAccountsPhones } from './endpoints/put-accounts-phones/put-accounts-phones'
import { putCustomersFundingSourcesBankAccountsMicroDeposits } from './endpoints/put-customers-funding-sources-bank-accounts-micro-deposits/put-customers-funding-sources-bank-accounts-micro-deposits'
import { putCustomersFundingSourcesBankAccountsYodleeVerification } from './endpoints/put-customers-funding-sources-bank-accounts-yodlee-verification/put-customers-funding-sources-bank-accounts-yodlee-verification'
import { putCustomersIncome } from './endpoints/put-customers-income/put-customers-income'
import { putCustomersPassword } from './endpoints/put-customers-password/put-customers-password'
import { putCustomersSecurityQuestions } from './endpoints/put-customers-security-questions/put-customers-security-questions'

import config from '@/config'
import { deleteAccountsCeaseDesist } from './endpoints/delete-accounts-cease-desist/delete-accounts-cease-desist'
import { getAccountsCardLostStolen } from './endpoints/get-accounts-card-lost-stolen/get-accounts-card-lost-stolen'
import { getAccountsCardholderAgreementRequest } from './endpoints/get-accounts-cardholder-agreement-request/get-accounts-cardholder-agreement-request'
import { getAccountsCeaseDesist } from './endpoints/get-accounts-cease-desist/get-accounts-cease-desist'
import { getAccountsCustomerServiceCases } from './endpoints/get-accounts-customer-service-cases/get-accounts-customer-service-cases'
import { getAccountsDocumentsLetterData } from './endpoints/get-accounts-documents-letter-data/get-accounts-documents-letter-data'
import { getAccountsDocumentsPdfBinary } from './endpoints/get-accounts-documents-pdf-binary/get-accounts-documents-pdf-binary'
import { getAccountsDocuments } from './endpoints/get-accounts-documents/get-accounts-documents'
import { getAccountsMessages } from './endpoints/get-accounts-messages/get-accounts-messages'
import { getAccountsNotificationPreferences } from './endpoints/get-accounts-notification-preferences/get-accounts-notification-preferences'
import { getAccountsOffersBalanceTransfer } from './endpoints/get-accounts-offers-balance-transfer/get-accounts-offers-balance-transfer'
import { getAccountsOffersCreditLineIncrease } from './endpoints/get-accounts-offers-credit-line-increase/get-accounts-offers-credit-line-increase'
import { getAccountsOffers } from './endpoints/get-accounts-offers/get-accounts-offers'
import { getAccountsPaymentsDueDate } from './endpoints/get-accounts-payments-due-date/get-accounts-payments-due-date'
import { getAccountsPaymentsEligibility } from './endpoints/get-accounts-payments-eligibility/get-accounts-payments-eligibility'
import { getAccountsPaymentsPayoffEstimator } from './endpoints/get-accounts-payments-payoff-estimator/get-accounts-payments-payoff-estimator'
import { getAccountsTransactionsAuthorizations } from './endpoints/get-accounts-transactions-authorizations/get-accounts-transactions-authorizations'
import { getAccountsTransactionsStatements } from './endpoints/get-accounts-transactions-statements/get-accounts-transactions-statements'
import { getAccountsTransactionsUnbilled } from './endpoints/get-accounts-transactions-unbilled/get-accounts-transactions-unbilled'
import { getCustomersOffers } from './endpoints/get-customers-offers/get-customers-offers'
import { postAccountsCardLostStolen } from './endpoints/post-accounts-card-lost-stolen/post-accounts-card-lost-stolen'
import { postAccountsCardholderAgreementRequest } from './endpoints/post-accounts-cardholder-agreement-request/post-accounts-cardholder-agreement-request'
import { postAccountsCustomerServiceCasesApplicationVerification } from './endpoints/post-accounts-customer-service-cases-application-verification/post-accounts-customer-service-cases-application-verification'
import { postAccountsCustomerServiceCasesCsInquiry } from './endpoints/post-accounts-customer-service-cases-cs-inquiry/post-accounts-customer-service-cases-cs-inquiry'
import { postAccountsCustomerServiceCasesDebtManagement } from './endpoints/post-accounts-customer-service-cases-debt-management/post-accounts-customer-service-cases-debt-management'
import { postAccountsCustomerServiceCasesDeceased } from './endpoints/post-accounts-customer-service-cases-deceased/post-accounts-customer-service-cases-deceased'
import { postAccountsCustomerServiceCasesFraudClaim } from './endpoints/post-accounts-customer-service-cases-fraud-claim/post-accounts-customer-service-cases-fraud-claim'
import { postAccountsCustomerServiceCasesIdentificationVerification } from './endpoints/post-accounts-customer-service-cases-identification-verification/post-accounts-customer-service-cases-identification-verification'
import { postAccountsCustomerServiceCasesNameChange } from './endpoints/post-accounts-customer-service-cases-name-change/post-accounts-customer-service-cases-name-change'
import { postAccountsCustomerServiceCasesPaymentVerification } from './endpoints/post-accounts-customer-service-cases-payment-verification/post-accounts-customer-service-cases-payment-verification'
import { postAccountsCustomerServiceCasesPowerOfAttorney } from './endpoints/post-accounts-customer-service-cases-power-of-attorney/post-accounts-customer-service-cases-power-of-attorney'
import { postAccountsCustomerServiceCasesScra } from './endpoints/post-accounts-customer-service-cases-scra/post-accounts-customer-service-cases-scra'
import { postAccountsPaymentsExpressAch } from './endpoints/post-accounts-payments-express-ach/post-accounts-payments-express-ach'
import { postAccountsPaymentsExpressDebit } from './endpoints/post-accounts-payments-express-debit/post-accounts-payments-express-debit'
import { postAccountsPaymentsExpressLivePerson } from './endpoints/post-accounts-payments-express-live-person/post-accounts-payments-express-live-person'
import { postAccountsPaymentsStandardAch } from './endpoints/post-accounts-payments-standard-ach/post-accounts-payments-standard-ach'
import { postCustomersDeviceFingerprint } from './endpoints/post-customers-device-fingerprint/post-customers-device-fingerprint'
import { postCustomersOffersAdditionalAccount } from './endpoints/post-customers-offers-additional-account/post-customers-offers-additional-account'
import { putAccountsCustomerServiceCases } from './endpoints/put-accounts-customer-service-cases/put-accounts-customer-service-cases'
import { putAccountsDocuments } from './endpoints/put-accounts-documents/put-accounts-documents'
import { putAccountsNotificationPreferences } from './endpoints/put-accounts-notification-preferences/put-accounts-notification-preferences'
import { putAccountsOffers } from './endpoints/put-accounts-offers/put-accounts-offers'
import { putAccountsPaymentsDueDate } from './endpoints/put-accounts-payments-due-date/put-accounts-payments-due-date'

/**
 * Fetches the OKTA Access Token from Okta's local storage.  Used as the authorization header for API calls.
 * @returns Okta access token.
 */
export const getAccessToken = (): string => {
  const accessToken = localStorage.getItem('okta-token-storage')
  if (accessToken) {
    return JSON.parse(accessToken).accessToken.accessToken
  }
  return ''
}

/**
 * Fetches the customer's unique ID from Okta's local storage.  Used as the CustomerID in API calls requiring that.
 * @returns Customer's unique ID
 */
export const getApiCustomerId = (): string => {
  const customerId = localStorage.getItem('okta-token-storage')
  if (customerId) {
    return JSON.parse(customerId).accessToken.claims.individualUniqueIdentifier
  }
  return ''
}

export const api = createApi({
  reducerPath: 'api',
  keepUnusedDataFor: 60,
  baseQuery: fetchBaseQuery({
    baseUrl: config.rtk.bffBasePath,
    /**
     * Adds the auth token to the header from Okta
     * @param headers - Header of the api
     */
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getAccessToken()}`)
      return headers
    },
  }),
  /**
   * Creates all the hooks from the endpoints
   * @param builder - Api builder
   */
  endpoints: builder => ({
    [putCustomersSecurityQuestions.endpointName]: putCustomersSecurityQuestions.thunk(builder),
    [putCustomersPassword.endpointName]: putCustomersPassword.thunk(builder),
    [getCustomersAccounts.endpointName]: getCustomersAccounts.thunk(builder),
    [putAccountsPhones.endpointName]: putAccountsPhones.thunk(builder),
    [putAccountsCardPin.endpointName]: putAccountsCardPin.thunk(builder),
    [getAccountsCardLock.endpointName]: getAccountsCardLock.thunk(builder),
    [putAccountsCardLock.endpointName]: putAccountsCardLock.thunk(builder),
    [postAccountsCardActivation.endpointName]: postAccountsCardActivation.thunk(builder),
    [getAccountsCardShipment.endpointName]: getAccountsCardShipment.thunk(builder),
    [getInvolvementsEmails.endpointName]: getInvolvementsEmails.thunk(builder),
    [getIdpSecurityQuestions.endpointName]: getIdpSecurityQuestions.thunk(builder),
    [getCustomersIncome.endpointName]: getCustomersIncome.thunk(builder),
    [putCustomersIncome.endpointName]: putCustomersIncome.thunk(builder),
    [putAccountsEmails.endpointName]: putAccountsEmails.thunk(builder),
    [putAccountsAddresses.endpointName]: putAccountsAddresses.thunk(builder),
    [getInvolvementsPhones.endpointName]: getInvolvementsPhones.thunk(builder),
    [getInvolvementsAddresses.endpointName]: getInvolvementsAddresses.thunk(builder),
    [getAccountsCardReplacement.endpointName]: getAccountsCardReplacement.thunk(builder),
    [postAccountsCardReplacement.endpointName]: postAccountsCardReplacement.thunk(builder),
    [getAccountsCardPin.endpointName]: getAccountsCardPin.thunk(builder),
    [getAccountsCardActivation.endpointName]: getAccountsCardActivation.thunk(builder),
    [getBanks.endpointName]: getBanks.thunk(builder),
    [getAccountsContacts.endpointName]: getAccountsContacts.thunk(builder),
    [getAccountsCardPlasticDesigns.endpointName]: getAccountsCardPlasticDesigns.thunk(builder),
    [putAccountsCardPlasticDesigns.endpointName]: putAccountsCardPlasticDesigns.thunk(builder),
    [getCustomersFundingSourcesBankAccounts.endpointName]:
    getCustomersFundingSourcesBankAccounts.thunk(builder),
    [postCustomersFundingSourcesBankAccounts.endpointName]:
    postCustomersFundingSourcesBankAccounts.thunk(builder),
    [postCustomersFundingSourcesBankAccountsMicroDeposits.endpointName]:
    postCustomersFundingSourcesBankAccountsMicroDeposits.thunk(builder),
    [putCustomersFundingSourcesBankAccountsMicroDeposits.endpointName]:
    putCustomersFundingSourcesBankAccountsMicroDeposits.thunk(builder),
    [postCustomersFundingSourcesBankAccountsYodleeVerification.endpointName]:
    postCustomersFundingSourcesBankAccountsYodleeVerification.thunk(builder),
    [putCustomersFundingSourcesBankAccountsYodleeVerification.endpointName]:
    putCustomersFundingSourcesBankAccountsYodleeVerification.thunk(builder),
    [deleteCustomersFundingSourcesBankAccounts.endpointName]:
    deleteCustomersFundingSourcesBankAccounts.thunk(builder),
    [getCustomersFundingSourcesDebitCards.endpointName]:
    getCustomersFundingSourcesDebitCards.thunk(builder),
    [postCustomersFundingSourcesDebitCards.endpointName]:
    postCustomersFundingSourcesDebitCards.thunk(builder),
    [getCustomersFundingSourcesDebitCardsAuthToken.endpointName]:
    getCustomersFundingSourcesDebitCardsAuthToken.thunk(builder),
    [deleteCustomersFundingSourcesDebitCards.endpointName]:
    deleteCustomersFundingSourcesDebitCards.thunk(builder),
    [getAccountsPaymentsHistory.endpointName]: getAccountsPaymentsHistory.thunk(builder),
    [getAccountsPaymentsPending.endpointName]: getAccountsPaymentsPending.thunk(builder),
    [deleteAccountsPayments.endpointName]: deleteAccountsPayments.thunk(builder),
    [getAccountsPaymentsAutoPayConfiguration.endpointName]:
    getAccountsPaymentsAutoPayConfiguration.thunk(builder),
    [postAccountsPaymentsAutoPayConfiguration.endpointName]:
    postAccountsPaymentsAutoPayConfiguration.thunk(builder),
    [putAccountsPaymentsAutoPayConfiguration.endpointName]:
    putAccountsPaymentsAutoPayConfiguration.thunk(builder),
    [deleteAccountsPaymentsAutoPayConfiguration.endpointName]:
    deleteAccountsPaymentsAutoPayConfiguration.thunk(builder),
    [getCustomersOffersAdditionalAccount.endpointName]:
    getCustomersOffersAdditionalAccount.thunk(builder),
    [getAccountsOffersReinstatement.endpointName]: getAccountsOffersReinstatement.thunk(builder),
    [getAccountsRewards.endpointName]: getAccountsRewards.thunk(builder),
    [getAccountsRewardsActivity.endpointName]: getAccountsRewardsActivity.thunk(builder),
		[postCustomersDeviceFingerprint.endpointName]: postCustomersDeviceFingerprint.thunk(builder),
		[getAccountsCeaseDesist.endpointName]: getAccountsCeaseDesist.thunk(builder),
		[deleteAccountsCeaseDesist.endpointName]: deleteAccountsCeaseDesist.thunk(builder),
		[getAccountsCardLostStolen.endpointName]: getAccountsCardLostStolen.thunk(builder),
		[postAccountsCardLostStolen.endpointName]: postAccountsCardLostStolen.thunk(builder),
		[getAccountsPaymentsEligibility.endpointName]: getAccountsPaymentsEligibility.thunk(builder),
		[getAccountsPaymentsDueDate.endpointName]: getAccountsPaymentsDueDate.thunk(builder),
		[putAccountsPaymentsDueDate.endpointName]: putAccountsPaymentsDueDate.thunk(builder),
		[getAccountsPaymentsPayoffEstimator.endpointName]: getAccountsPaymentsPayoffEstimator.thunk(builder),
		[postAccountsPaymentsStandardAch.endpointName]: postAccountsPaymentsStandardAch.thunk(builder),
		[postAccountsPaymentsExpressAch.endpointName]: postAccountsPaymentsExpressAch.thunk(builder),
		[postAccountsPaymentsExpressDebit.endpointName]: postAccountsPaymentsExpressDebit.thunk(builder),
		[postAccountsPaymentsExpressLivePerson.endpointName]: postAccountsPaymentsExpressLivePerson.thunk(builder),
		[getAccountsTransactionsAuthorizations.endpointName]: getAccountsTransactionsAuthorizations.thunk(builder),
		[getAccountsTransactionsUnbilled.endpointName]: getAccountsTransactionsUnbilled.thunk(builder),
		[getAccountsTransactionsStatements.endpointName]: getAccountsTransactionsStatements.thunk(builder),
		[getCustomersOffers.endpointName]: getCustomersOffers.thunk(builder),
		[postCustomersOffersAdditionalAccount.endpointName]: postCustomersOffersAdditionalAccount.thunk(builder),
		[getAccountsOffers.endpointName]: getAccountsOffers.thunk(builder),
		[getAccountsOffersBalanceTransfer.endpointName]: getAccountsOffersBalanceTransfer.thunk(builder),
		[getAccountsOffersCreditLineIncrease.endpointName]: getAccountsOffersCreditLineIncrease.thunk(builder),
		[putAccountsOffers.endpointName]: putAccountsOffers.thunk(builder),
		[getAccountsNotificationPreferences.endpointName]: getAccountsNotificationPreferences.thunk(builder),
		[putAccountsNotificationPreferences.endpointName]: putAccountsNotificationPreferences.thunk(builder),
		[getAccountsCardholderAgreementRequest.endpointName]: getAccountsCardholderAgreementRequest.thunk(builder),
		[postAccountsCardholderAgreementRequest.endpointName]: postAccountsCardholderAgreementRequest.thunk(builder),
		[getAccountsMessages.endpointName]: getAccountsMessages.thunk(builder),
		[getAccountsDocuments.endpointName]: getAccountsDocuments.thunk(builder),
		[getAccountsDocumentsLetterData.endpointName]: getAccountsDocumentsLetterData.thunk(builder),
		[getAccountsDocumentsPdfBinary.endpointName]: getAccountsDocumentsPdfBinary.thunk(builder),
		[putAccountsDocuments.endpointName]: putAccountsDocuments.thunk(builder),
    [postAccountsCustomerServiceCasesApplicationVerification.endpointName]: postAccountsCustomerServiceCasesApplicationVerification.thunk(builder),
		[getAccountsCustomerServiceCases.endpointName]: getAccountsCustomerServiceCases.thunk(builder),
		[putAccountsCustomerServiceCases.endpointName]: putAccountsCustomerServiceCases.thunk(builder),
		[postAccountsCustomerServiceCasesNameChange.endpointName]: postAccountsCustomerServiceCasesNameChange.thunk(builder),
		[postAccountsCustomerServiceCasesPowerOfAttorney.endpointName]: postAccountsCustomerServiceCasesPowerOfAttorney.thunk(builder),
		[postAccountsCustomerServiceCasesScra.endpointName]: postAccountsCustomerServiceCasesScra.thunk(builder),
		[postAccountsCustomerServiceCasesPaymentVerification.endpointName]: postAccountsCustomerServiceCasesPaymentVerification.thunk(builder),
		[postAccountsCustomerServiceCasesIdentificationVerification.endpointName]: postAccountsCustomerServiceCasesIdentificationVerification.thunk(builder),
		[postAccountsCustomerServiceCasesFraudClaim.endpointName]: postAccountsCustomerServiceCasesFraudClaim.thunk(builder),
		[postAccountsCustomerServiceCasesDebtManagement.endpointName]: postAccountsCustomerServiceCasesDebtManagement.thunk(builder),
		[postAccountsCustomerServiceCasesDeceased.endpointName]: postAccountsCustomerServiceCasesDeceased.thunk(builder),
		[postAccountsCustomerServiceCasesCsInquiry.endpointName]: postAccountsCustomerServiceCasesCsInquiry.thunk(builder),
 }),
})

export const {
  usePutCustomersSecurityQuestionsMutation,
  usePutCustomersPasswordMutation,
  useGetCustomersAccountsQuery,
  useLazyGetCustomersAccountsQuery,
  usePutAccountsPhonesMutation,
  usePutAccountsCardPinMutation,
  useGetAccountsCardLockQuery,
  useLazyGetAccountsCardLockQuery,
  usePutAccountsCardLockMutation,
  usePostAccountsCardActivationMutation,
  useGetAccountsCardShipmentQuery,
  useLazyGetAccountsCardShipmentQuery,
  useGetInvolvementsEmailsQuery,
  useLazyGetInvolvementsEmailsQuery,
  useGetIdpSecurityQuestionsQuery,
  useLazyGetIdpSecurityQuestionsQuery,
  useGetCustomersIncomeQuery,
  useLazyGetCustomersIncomeQuery,
  usePutCustomersIncomeMutation,
  usePutAccountsEmailsMutation,
  usePutAccountsAddressesMutation,
  useGetInvolvementsPhonesQuery,
  useLazyGetInvolvementsPhonesQuery,
  useGetInvolvementsAddressesQuery,
  useLazyGetInvolvementsAddressesQuery,
  useGetAccountsCardReplacementQuery,
  useLazyGetAccountsCardReplacementQuery,
  usePostAccountsCardReplacementMutation,
  useGetAccountsCardPinQuery,
  useLazyGetAccountsCardPinQuery,
  useGetAccountsCardActivationQuery,
  useLazyGetAccountsCardActivationQuery,
  useGetBanksQuery,
  useLazyGetBanksQuery,
  useGetAccountsContactsQuery,
  useLazyGetAccountsContactsQuery,
  useGetAccountsCardPlasticDesignsQuery,
  useLazyGetAccountsCardPlasticDesignsQuery,
  usePutAccountsCardPlasticDesignsMutation,
  useGetCustomersFundingSourcesBankAccountsQuery,
  useLazyGetCustomersFundingSourcesBankAccountsQuery,
  usePostCustomersFundingSourcesBankAccountsMutation,
  usePostCustomersFundingSourcesBankAccountsMicroDepositsMutation,
  usePutCustomersFundingSourcesBankAccountsMicroDepositsMutation,
  usePostCustomersFundingSourcesBankAccountsYodleeVerificationMutation,
  usePutCustomersFundingSourcesBankAccountsYodleeVerificationMutation,
  useLazyDeleteCustomersFundingSourcesDebitCardsQuery,
  useLazyDeleteCustomersFundingSourcesBankAccountsQuery,
  useGetCustomersFundingSourcesDebitCardsQuery,
  useLazyGetCustomersFundingSourcesDebitCardsQuery,
  usePostCustomersFundingSourcesDebitCardsMutation,
  useGetCustomersFundingSourcesDebitCardsAuthTokenQuery,
  useLazyGetCustomersFundingSourcesDebitCardsAuthTokenQuery,
  useDeleteCustomersFundingSourcesBankAccountsQuery,
  useGetAccountsPaymentsHistoryQuery,
  useLazyGetAccountsPaymentsHistoryQuery,
  useGetAccountsPaymentsPendingQuery,
  useLazyGetAccountsPaymentsPendingQuery,
  useDeleteAccountsPaymentsQuery,
  useLazyDeleteAccountsPaymentsAutoPayConfigurationQuery,
  useLazyDeleteAccountsPaymentsQuery,
  useDeleteCustomersFundingSourcesDebitCardsQuery,
  useGetAccountsPaymentsAutoPayConfigurationQuery,
  useLazyGetAccountsPaymentsAutoPayConfigurationQuery,
  usePostAccountsPaymentsAutoPayConfigurationMutation,
  usePutAccountsPaymentsAutoPayConfigurationMutation,
  useDeleteAccountsPaymentsAutoPayConfigurationQuery,
  useGetCustomersOffersAdditionalAccountQuery,
  useLazyGetCustomersOffersAdditionalAccountQuery,
  useGetAccountsOffersReinstatementQuery,
  useLazyGetAccountsOffersReinstatementQuery,
  useGetAccountsRewardsQuery,
  useLazyGetAccountsRewardsQuery,
  useGetAccountsRewardsActivityQuery,
  useLazyGetAccountsRewardsActivityQuery,
	usePostCustomersDeviceFingerprintMutation,
	useGetAccountsCeaseDesistQuery,
	useLazyGetAccountsCeaseDesistQuery,
	useDeleteAccountsCeaseDesistQuery,
	useLazyDeleteAccountsCeaseDesistQuery,
	useGetAccountsCardLostStolenQuery,
	useLazyGetAccountsCardLostStolenQuery,
	usePostAccountsCardLostStolenMutation,
	useGetAccountsPaymentsEligibilityQuery,
	useLazyGetAccountsPaymentsEligibilityQuery,
	useGetAccountsPaymentsDueDateQuery,
	useLazyGetAccountsPaymentsDueDateQuery,
	usePutAccountsPaymentsDueDateMutation,
	useGetAccountsPaymentsPayoffEstimatorQuery,
	useLazyGetAccountsPaymentsPayoffEstimatorQuery,
	usePostAccountsPaymentsStandardAchMutation,
	usePostAccountsPaymentsExpressAchMutation,
	usePostAccountsPaymentsExpressDebitMutation,
	usePostAccountsPaymentsExpressLivePersonMutation,
	useGetAccountsTransactionsAuthorizationsQuery,
	useLazyGetAccountsTransactionsAuthorizationsQuery,
	useGetAccountsTransactionsUnbilledQuery,
	useLazyGetAccountsTransactionsUnbilledQuery,
	useGetAccountsTransactionsStatementsQuery,
	useLazyGetAccountsTransactionsStatementsQuery,
	useGetCustomersOffersQuery,
	useLazyGetCustomersOffersQuery,
	usePostCustomersOffersAdditionalAccountMutation,
	useGetAccountsOffersQuery,
	useLazyGetAccountsOffersQuery,
	useGetAccountsOffersBalanceTransferQuery,
	useLazyGetAccountsOffersBalanceTransferQuery,
	useGetAccountsOffersCreditLineIncreaseQuery,
	useLazyGetAccountsOffersCreditLineIncreaseQuery,
	usePutAccountsOffersMutation,
	useGetAccountsNotificationPreferencesQuery,
	useLazyGetAccountsNotificationPreferencesQuery,
	usePutAccountsNotificationPreferencesMutation,
	useGetAccountsCardholderAgreementRequestQuery,
	useLazyGetAccountsCardholderAgreementRequestQuery,
	usePostAccountsCardholderAgreementRequestMutation,
	useGetAccountsMessagesQuery,
	useLazyGetAccountsMessagesQuery,
	useGetAccountsDocumentsQuery,
	useLazyGetAccountsDocumentsQuery,
	useGetAccountsDocumentsLetterDataQuery,
	useLazyGetAccountsDocumentsLetterDataQuery,
	useGetAccountsDocumentsPdfBinaryQuery,
	useLazyGetAccountsDocumentsPdfBinaryQuery,
	usePutAccountsDocumentsMutation,
	useGetAccountsCustomerServiceCasesQuery,
	useLazyGetAccountsCustomerServiceCasesQuery,
	usePutAccountsCustomerServiceCasesMutation,
	usePostAccountsCustomerServiceCasesNameChangeMutation,
	usePostAccountsCustomerServiceCasesPowerOfAttorneyMutation,
	usePostAccountsCustomerServiceCasesScraMutation,
	usePostAccountsCustomerServiceCasesPaymentVerificationMutation,
	usePostAccountsCustomerServiceCasesIdentificationVerificationMutation,
	usePostAccountsCustomerServiceCasesFraudClaimMutation,
	usePostAccountsCustomerServiceCasesDebtManagementMutation,
	usePostAccountsCustomerServiceCasesDeceasedMutation,
	usePostAccountsCustomerServiceCasesCsInquiryMutation,
} = api
