import { PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { APIRequest, postAccountsPaymentsStandardAch, PostAccountsPaymentsStandardAch } from './post-accounts-payments-standard-ach'
import { api } from '../../api'

import { AnyForNow } from '@/shared/utils/anyTypes.types'

// Keys should only be changed here
/*
interface ReduxResponse {
paymentRequestId: string
paymentConfirmation: string
paymentStatus: string
}

*/

/**
 * Returns the url depending on the request
 * @param _request - Request of the API
 */
const getUrl = (_request?: PostAccountsPaymentsStandardAch['APIRequest']): string => {
  // Create url to be returned to the query
  return '/accounts/{account-id}/payments/standard-ach'
}

interface Meta {
  arg: {
    endpointName: string
    originalArgs: APIRequest
  }
  baseMutationMeta: {
    request: {
      body: APIRequest['body']
    }
  }
}

/**
 * Transform the response to be used within redux
 * @param _response - response from the API
 */
const transformResponseToReduxResponse = (_response: PostAccountsPaymentsStandardAch['APISuccessResponse']): AnyForNow => {
  return {
    // Map keys from response to Redux Response
    // They can be the same key name but this is the only place where the response keys are used.
    // Everywhere else we should be using the transform keys
  }
}

/**
 * What to do when the API was successful.
 * THIS WILL UPDATE THE SliceState
 * @param _state - The current state of the slice.
 * @param _action - The action containing the successful response.
 */
const handleFulfilled = (_state: AnyForNow, _action: PayloadAction<PostAccountsPaymentsStandardAch['APISuccessResponse'], string, Meta>): void => {
  transformResponseToReduxResponse(_action.payload)
  // Do something with the transformed state in the slice
}

/**
 * What to do if the API failed (rejected)
 * @param _state - The current state of the account.
 * @param _action - The action containing the error response.
 */
const handleRejected = (_state: AnyForNow, _action: PayloadAction<PostAccountsPaymentsStandardAch['APIErrorResponse']>): void => {
  // Do something to the StateSlice when something fails. Most of the time we won't need something like this because we'll return the failure response from the API call itself. But if we need to update state here we can.
}

/**
 * Utility function that creates reducer cases for the API call.
 * @param _builder - The reducer builder.
 */
const createCases = (_builder: ActionReducerMapBuilder<AnyForNow>): void => {
  const endpoint = api.endpoints[postAccountsPaymentsStandardAch.endpointName]
  _builder.addMatcher(endpoint.matchFulfilled, (state, action) => {
    handleFulfilled(state, action as unknown as PayloadAction<PostAccountsPaymentsStandardAch['APISuccessResponse'], string, Meta>)
  })
  _builder.addMatcher(endpoint.matchRejected, (state, action) => {
    handleRejected(state, action as unknown as PayloadAction<PostAccountsPaymentsStandardAch['APIErrorResponse']>)
  })
}

export const postAccountsPaymentsStandardAchRedux = {
  createCases,
  getUrl,
}
