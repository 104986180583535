import { createLinkComponent } from '@creditonebank/identity-ui'
import React, { FC, StrictMode, useEffect, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import './assets/styles/global.css'
import config from './config'
import TestPage from './pages/test-page'
import { NavLink } from './shared/components/nav-link'
import { loadAdobeScript } from './shared/utils/loadAdobeScript'

const OktaProtectedRoute = React.lazy(() => import('./layouts/okta-protected-route/okta-protected-route'))
const AppShellLayout = React.lazy(
  () => import('./layouts/app-shell-layout'),
)
const AccountLayout = React.lazy(() => import('./layouts/account-layout/account-layout'))

loadAdobeScript()
const StoreProvider = React.lazy(() => import('./redux/provider'))
const Error404Page = React.lazy(
  () => import('./pages/error-404/error-404.page'),
)

const LoginCallbackPage = React.lazy(() => import('./pages/login-callback/login-callback'))
const Dashboard = React.lazy(() => import('./pages/dashboard/dashboard.page'))
const PasswordExpiredPage = React.lazy(
  () => import('./pages/password-expired/password-expired.page'),
)
const SecurityQuestionsPage = React.lazy(
  () => import('./pages/security-questions/security-questions.page'),
)
const ProfilePage = React.lazy(() => import('./pages/profile/profile.page'))
const CardShipmentTrackerPage = React.lazy(
  () => import('./pages/card-shipment-tracker/card-shipment-tracker.page'),
)
const ActivateCardPage = React.lazy(
  () => import('./pages/activate-card/activate-card.page'),
)
const ChangePinPage = React.lazy(
  () => import('./pages/change-pin/change-pin.page'),
)
const LockCardPage = React.lazy(
  () => import('./pages/lock-card/lock-card.page'),
)
const AccountOverview = React.lazy(
  () => import('./pages/account-overview/account-overview.page'),
)
const SignOutPage = React.lazy(() => import('./pages/sign-out/sign-out.page'))

const ManageCardPage = React.lazy(() => import('./pages/manage-card/manage-card.page'))
createLinkComponent(NavLink)

const router = createBrowserRouter([
  {
    path: '/login/callback',
    element: <React.Suspense><LoginCallbackPage /></React.Suspense>,
  },
  {
    path: '/login/sign-out',
    element: <React.Suspense><SignOutPage /></React.Suspense>,
  },
  {
    path: '/404',
    element: <React.Suspense><Error404Page /></React.Suspense>,
  },
  {
    path: '/',
    element: (
      <React.Suspense fallback={<div>...loading</div>}>
        <OktaProtectedRoute />
      </React.Suspense>
    ),
    children: [
      {
        path: 'login/callback',
        element: <Navigate to="/" />,
      },
      {
        path: 'login/sign-out',
        element: <SignOutPage />,
      },
      {
        path: 'password-expired',
        element: <PasswordExpiredPage />,
      },
      {
        path: '/',
        element: <AppShellLayout />,
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
          {
            path: 'account/:accountId',
            element: <AccountLayout />,
            children: [
              {
                index: true,
                element: <AccountOverview />,
              },
              {
                path: 'payments',
                element: (
                  <div>Payments</div>
                ),
              },
              {
                path: 'lock',
                element: <LockCardPage />,
              },
              {
                path: 'shipment',
                element: <CardShipmentTrackerPage />,
              },
              {
                path: 'pin',
                element: <ChangePinPage />,
              },
              {
                path: 'activate',
                element: <ActivateCardPage />,
              },
              {
                path: 'manage-card',
                element: <ManageCardPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'profile',
        element: <ProfilePage />,
        children: [
          {
            path: 'edit/security-questions',
            element: <SecurityQuestionsPage />,
          },
        ],
      },
      {
        path: 'test-page',
        element: <TestPage />,
      },
      { path: '*', element: <Error404Page /> },
    ],
  },
])

interface Config {
  AUTH_CLIENT_ID: string
  AUTH_ISSUER: string
  BFF_URL: string
  LOGOUT_URI: string
  REDIRECT_URI: string
  ADOBE_URL: string
}

/**
 * Updates the `./config` file with the config from the server
 * @param newConfig - {@link Config}
 */
const updateConfig = (newConfig: Config) => {
  config.auth.clientId = newConfig.AUTH_CLIENT_ID
  config.auth.issuer = newConfig.AUTH_ISSUER
  config.auth.postLogoutRedirectUri = newConfig.LOGOUT_URI
  config.auth.redirectUri = newConfig.REDIRECT_URI
  config.adobe.url = 'https://assets.adobedtm.com/b598760f96d3/7c72b447e495/launch-ba89eba38d4d-staging.min.js'
}
/**
 * Loads the `/api/config` file and displays the app when the config is loaded.
 * @param props - The children
 */
const ConfigLoader: FC<{ children: JSX.Element }> = (props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const isMounted = useRef(false)
  useEffect(() => {
    if (!isLoaded && !isMounted.current) {
      fetch('/api/config')
        .then(res => res.json())
        .then((res) => {
          updateConfig(res)
          setIsLoaded(true)
        })
    }
    isMounted.current = true
    return () => {
      isMounted.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoaded) {
    return props.children
  }

  return null
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ConfigLoader>
      <React.Suspense>
        <StoreProvider>
          <RouterProvider router={router} />
        </StoreProvider>
      </React.Suspense>
    </ConfigLoader>
  </StrictMode>,
)
