import { Link } from 'react-router-dom'

import { FlyoutMenu } from '@/shared/components/fly-out-menu/flyout-menu'

/**
 * Is a test page for testing features or apis
 */
const TestPage = () => {
  /**
   *
   */
  const handleOnClick = async (): Promise<void> => {
    // TODO document why this async arrow function is empty

  }

  return (
    <div>
      <FlyoutMenu />
      <h1>
        <button onClick={() => { handleOnClick() }}>Click Here</button>
        <span>I&apos;m a test page</span>
        <Link to="/404">404 Page</Link>
      </h1>
    </div>
  )
}

export default TestPage
