import { type PayloadAction, type ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { type APIRequest, getAccountsCardShipment, type GetAccountsCardShipment } from './get-accounts-card-shipment'
import { api } from '../../api'

import { type AccountsState } from '@/redux/reducers/accounts/accounts'
import { CardShipment } from '@/redux/reducers/accounts/types'

// Keys should only be changed here
type ReduxResponse = CardShipment

/**
 * Returns the url depending on the request
 * @param request - Request of the API
 */
const getUrl = (request?: GetAccountsCardShipment['APIRequest']): string => {
  // Create url to be returned to the query
  return `/accounts/${request?.path.accountId}/card/shipment`
}

interface Meta {
  arg: {
    endpointName: string
    originalArgs: APIRequest
  }

}

/**
 * Transform the response to be used within redux
 * @param response - response from the API
 */
const transformResponseToReduxResponse = (response: GetAccountsCardShipment['APISuccessResponse']): ReduxResponse => {
  return {
    // Map keys from response to Redux Response
    // They can be the same key name but this is the only place where the response keys are used.
    // Everywhere else we should be using the transform keys
    issuanceReason: response.cardIssuanceReason ?? '',
    cardType: response.cardType ?? '',
    deliveryDate: response.deliveryDate ?? -1,
    deliveryETA: response.deliveryETA ?? -1,
    eventTime: response.eventTime ?? -1,
    plasticCode: response.plasticCode ?? '',
    productCode: response.productCode ?? '',
    shipCarrier: response.shipCarrier ?? '',
    shipDate: response.shipDate ?? -1,
    shippingAddress: response.shippingAddress ?? '',
    status: response.status ?? '',
    statusDate: response.statusDate ?? -1,
    trackingNumber: response.trackingNumber ?? '',
    zipCode: response.zipCode ?? '',
  }
}

/**
 * What to do when the API was successful.
 * THIS WILL UPDATE THE SliceState
 * @param state - The current state of the slice.
 * @param action - The action containing the successful response.
 */
const handleFulfilled = (state: AccountsState, action: PayloadAction<GetAccountsCardShipment['APISuccessResponse'], string, Meta>): void => {
  const transformedResponse: ReduxResponse = transformResponseToReduxResponse(action.payload)
  // Do something with the transformed state in the slice
  const currentAccountId = action.meta.arg.originalArgs.path.accountId
  state.forEach((account) => {
    if (account.id === currentAccountId) {
      account.cardShipment = transformedResponse
    }
  })
}

/**
 * What to do if the API failed (rejected)
 * @param _state - The current state of the account.
 * @param _action - The action containing the error response.
 */
const handleRejected = (_state: AccountsState, _action: PayloadAction<GetAccountsCardShipment['APIErrorResponse']>): void => {
  // Do something to the StateSlice when something fails. Most of the time we won't need something like this because we'll return the failure response from the API call itself. But if we need to update state here we can.
}

/**
 * Utility function that creates reducer cases for the API call.
 * YOU PROBABLY DON'T NEED TO CHANGE THIS
 * @param builder - The reducer builder.
 */
const createCases = (builder: ActionReducerMapBuilder<AccountsState>): void => {
  const endpoint = api.endpoints[getAccountsCardShipment.endpointName]
  builder.addMatcher(endpoint.matchFulfilled, (state, action) => {
    handleFulfilled(state, action as PayloadAction<GetAccountsCardShipment['APISuccessResponse'], string, Meta>)
  })
  builder.addMatcher(endpoint.matchRejected, (state, action) => {
    handleRejected(state, action as unknown as PayloadAction<GetAccountsCardShipment['APIErrorResponse']>)
  })
}

export const getAccountsCardShipmentRedux = {
  createCases,
  getUrl,
}
