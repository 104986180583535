import { type QueryDefinition, type BaseQueryFn, type EndpointBuilder, type FetchArgs, type FetchBaseQueryError, type FetchBaseQueryMeta, type MutationDefinition } from '@reduxjs/toolkit/query/react'

import { RealAny, KeyStringAny } from '@/shared/utils/anyTypes.types'

export type Builder = EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, never, 'api'>

export type QueryThunk<T = RealAny, K = RealAny> = QueryDefinition<T, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, never, K, 'api'>

export type MutationThunk<T = RealAny, K = RealAny> = MutationDefinition<T, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>, never, K, 'api'>

/**
 * Represents an error response from the BFF
 */
export interface ErrorResponse<T = number> {
  /**
   * An array of error messages (empty for successful requests).
   */
  errorMsg: string[]
  /**
   * The HTTP status code
   */
  statusCode: T
  /**
   * The timestamp when the request was processed.
   */
  timestamp: string
}

export interface BaseAPIRequest {
  /**
   * Any query parameters that need to be passed to the API call
   */
  params?: KeyStringAny
  /**
   * The body for
   */
  body?: KeyStringAny
  /**
   * query string parameters
   */
  query?: KeyStringAny
}

/**
 * The different HTTP Methods available.
 */
export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

/**
 * The interface that is used inside of the RTK Query files
 */
export interface BaseRTKInterface {
  StatusCodes: number
  APIErrorResponse: ErrorResponse<number>
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  APISuccessResponse: KeyStringAny | void
  APIRequest: KeyStringAny | undefined
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  APIResponse: KeyStringAny | void
  APISuccessStatusCode: number
}
