import { type OptionProps, optionProps } from '@creditonebank/identity-ui/dist/components/select-input/option-props'

import { GetIdpSecurityQuestions } from './get-idp-security-questions'

/**
 * Returns the url depending on the request
 */
const getUrl = (): string => {
  // Create url to be returned to the query
  return '/idp/security-questions'
}

/**
 * Transforms the response from the `useGetCustomerSecurityQuestionsQuery` hook into a grouping of all of the questions.
 *
 * Returns `data` if `data` is either `undefined` or an error response {@link GetIdpSecurityQuestions.APIErrorResponse}
 * @param data - {@link GetIdpSecurityQuestions.APIResponse}
 * @returns - Transforms the response from the `useGetCustomerSecurityQuestionsQuery` hook into a grouping of all of the questions.
 */
const transformResponse = (data?: GetIdpSecurityQuestions['APIResponse']) => {
  if (data === undefined || 'errorMsg' in data) {
    return data
  }

  // Type out the return variable.
  const questions: TransformedResponse = {
    original: data,
    question1: [],
    question2: [],
    question3: [],
  }

  // Create the OptionProps packages and push them into the correct array.
  data.forEach((e) => {
    const option = optionProps(e.text, { id: `option_${e.id?.toString()}` })
    switch (e.group) {
      case '1':
        questions.question1.push(option)
        break
      case '2':
        questions.question2.push(option)
        break
      case '3':
        questions.question3.push(option)
        break
    }
  })

  // Return our transformed response.
  return questions
}

interface TransformedResponse {
  original: GetIdpSecurityQuestions['APISuccessResponse']
  question1: OptionProps[]
  question2: OptionProps[]
  question3: OptionProps[]
}

export interface GetIdpSecurityQuestionsRedux {
  transformedResponse: TransformedResponse
}

export const getIdpSecurityQuestionsRedux = {
  getUrl,
  transformResponse,
}
