import { createSlice } from '@reduxjs/toolkit/react'

import { Account } from './types'
import { productTypes, cardTypes } from './types/accounts.types'

import { getAccountsCardLockRedux } from '@/redux/apis/endpoints/get-accounts-card-lock/get-accounts-card-lock-redux'
import { getAccountsCardPinRedux } from '@/redux/apis/endpoints/get-accounts-card-pin/get-accounts-card-pin-redux'
import { getAccountsCardReplacementRedux } from '@/redux/apis/endpoints/get-accounts-card-replacement/get-accounts-card-replacement-redux'
import { getAccountsCardShipmentRedux } from '@/redux/apis/endpoints/get-accounts-card-shipment/get-accounts-card-shipment-redux'
import { getCustomersAccountsRedux } from '@/redux/apis/endpoints/get-customers-accounts/get-customers-accounts-redux'
import { postAccountsCardActivationRedux } from '@/redux/apis/endpoints/post-accounts-card-activation/post-accounts-card-activation-redux'
import { putAccountsCardLockRedux } from '@/redux/apis/endpoints/put-accounts-card-lock/put-accounts-card-lock-redux'
import { putAccountsCardPinRedux } from '@/redux/apis/endpoints/put-accounts-card-pin/put-accounts-card-pin-redux'

/**
 * Creates the inital account to defaults
 * @param newAccount - new account partial to fill in missing properties
 */
export const initAccount = (newAccount: Partial<Account> = {}): Account => ({
  id: '2fa85f64-5717-3762-b3fc-2c963f66afa7',
  name: 'John Doe',
  productKey: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  isSecuredCard: false,
  involvementId: 'ae787cc8-beaf-46da-a88a-6ef38ab5239e',
  savingAccountNumber: '2fe85f64-5717-4562-b3fc-2c963f66ajh7',
  device: {
    last4Digit: '7525',
    network: 'VISA',
    expiryDate: '2025-11-01',
    isActivated: true,
    status: 'NORMAL',
    lastPlasticDate: 1729698404,
    currentPlasticDesignCode: 'AF',
  },
  balance: {
    creditLimit: 500,
    balance: 25.91,
    creditLimitForCash: 125,
    availableCredit: 474.55,
    availableCreditForCash: 125,
    totalAvailableCredit: 0,
    totalAvailableCreditForCash: 0,
    minimumPayment: 25.91,
    hasMinimumPaymentMet: false,
    paymentDueDate: '2024-09-23T17:44:33Z',
    isPastDue: false,
    isDelinquent: false,
    daysDelinquent: 50,
    lastStatementBalance: 25.91,
    lastPaymentAmount: 8,
    totalPaymentsAndCredits: 0,
    totalTransactions: 5,
  },
  hasCardActivationAttemptsRemaining: false,
  isCardLocked: false,
  replacementCard: undefined,
  cardShipment: undefined,
  productType: productTypes.WANDER,
  cardType: cardTypes.VISA,
  isAutoPayEnabled: true,
  scheduledPaymentsCount: 1,
  accountAlertBanner: [],
  pinEligibility: false,
  paymentHistory: [],
  paymentPending: [],
  isActivated: true,
  isCardActivationEligible: false,
  paymentAutoPay: {
    bankAccountId: 'asdf51651qfw1f89qwef',
    bankAccountLast4: '4567',
    bankName: 'Chase',
    paymentPurpose: 'Balance',
    paymentAmount: 45.05,
    paymentDay: 15,
    firstAutoPayDate: '',
  },
  ...newAccount,
})

export type AccountsState = Account[]

const initialState: AccountsState = []

const sliceTest = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
  },
  /**
   * Creates cases for all apis that use the AccountsSlice state
   * @param builder - the AccountsSlice
   */
  extraReducers: (builder) => {
    getCustomersAccountsRedux.createCasesAccount(builder)
    getAccountsCardPinRedux.createCases(builder)
    getAccountsCardReplacementRedux.createCases(builder)
    getAccountsCardLockRedux.createCases(builder)
    getAccountsCardShipmentRedux.createCases(builder)
    postAccountsCardActivationRedux.createCases(builder)
    putAccountsCardLockRedux.createCases(builder)
    putAccountsCardPinRedux.createCases(builder)
  },
})

export default sliceTest.reducer
