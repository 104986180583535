import { PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { APIRequest, getAccountsRewardsActivity, GetAccountsRewardsActivity } from './get-accounts-rewards-activity'
import { api } from '../../api'

import { RewardActivityDetails } from '@/apis/models/SwaggerTypes'
import { AccountsState } from '@/redux/reducers/accounts/accounts'
import { RewardsActivity, RewardsEarned, RewardsRedeemed } from '@/redux/reducers/accounts/types'

// Keys should only be changed here
type ReduxResponse = RewardsActivity

/**
 * Returns the url depending on the request
 * @param request - Request of the API
 */
const getUrl = (request?: GetAccountsRewardsActivity['APIRequest']): string => {
  // Create url to be returned to the query
  return `/accounts/${request?.path.accountId}/rewards/activity`
}

interface Meta {
  arg: {
    endpointName: string
    originalArgs: APIRequest
  }

}

/**
 * Grab the earned details from the response
 * @param details - {@link RewardActivityDetails}
 */
const grabEarned = (details?: RewardActivityDetails): RewardsEarned[] => {
  if (!details?.earned) {
    return []
  }

  return details?.earned?.map(
    (entry) => {
      const temp: RewardsEarned = {
        date: +(entry.date ?? -1),
        description: entry.description ?? '',
        merchantName: entry.merchantName ?? '',
        transactionAmount: entry.transactionAmount ?? -1,
        earnedAmount: entry.earnedAmount ?? -1,
      }
      return temp
    })
}

/**
 * Grab the redeemed details from the response
 * @param details - {@link RewardActivityDetails}
 */
const grabRedeemed = (details?: RewardActivityDetails): RewardsRedeemed[] => {
  if (!details?.redeemed) {
    return []
  }

  return details?.redeemed?.map(
    (entry) => {
      const temp: RewardsRedeemed = {
        date: +(entry.date ?? -1),
        type: entry.type ?? '',
        description: entry.description ?? '',
        redemptionAmount: entry.redemptionAmount ?? -1,
      }
      return temp
    })
}

/**
 * Transform the response to be used within redux
 * @param response - response from the API
 */
const transformResponseToReduxResponse = (response: GetAccountsRewardsActivity['APISuccessResponse']): ReduxResponse => {
  const last30Earned: RewardsEarned[] = grabEarned(response.last30Days)

  const last30Redeemed: RewardsRedeemed[] = grabRedeemed(response.last30Days)

  const lastStatementEarned: RewardsEarned[] = grabEarned(response.lastStaementPeriod)

  const lastStatementRedeemed: RewardsRedeemed[] = grabRedeemed(response.lastStaementPeriod)

  return {
    last30Days: {
      earned: last30Earned,
      redeemed: last30Redeemed,
    },
    lastStatementPeriod: {
      earned: lastStatementEarned,
      redeemed: lastStatementRedeemed,
    },
  }
}

/**
 * What to do when the API was successful.
 * THIS WILL UPDATE THE SliceState
 * @param state - The current state of the slice.
 * @param action - The action containing the successful response.
 */
const handleFulfilled = (state: AccountsState, action: PayloadAction<GetAccountsRewardsActivity['APISuccessResponse'], string, Meta>): void => {
  const activity = transformResponseToReduxResponse(action.payload)
  const account = state.find(account => account.id === action.meta.arg.originalArgs.path.accountId)
  if (account !== undefined) {
    const existingSummary = account.rewardsSummary
    account.rewardsSummary = { ...existingSummary, activity }
  }
}

/**
 * What to do if the API failed (rejected)
 * @param _state - The current state of the account.
 * @param _action - The action containing the error response.
 */
const handleRejected = (_state: AccountsState, _action: PayloadAction<GetAccountsRewardsActivity['APIErrorResponse']>): void => {
  // Do something to the StateSlice when something fails. Most of the time we won't need something like this because we'll return the failure response from the API call itself. But if we need to update state here we can.
}

/**
 * Utility function that creates reducer cases for the API call.
 * @param builder - The reducer builder.
 */
const createCases = (builder: ActionReducerMapBuilder<AccountsState>): void => {
  const endpoint = api.endpoints[getAccountsRewardsActivity.endpointName]
  builder.addMatcher(endpoint.matchFulfilled, (state, action) => {
    handleFulfilled(state, action as PayloadAction<GetAccountsRewardsActivity['APISuccessResponse'], string, Meta>)
  })
  builder.addMatcher(endpoint.matchRejected, (state, action) => {
    handleRejected(state, action as unknown as PayloadAction<GetAccountsRewardsActivity['APIErrorResponse']>)
  })
}

export const getAccountsRewardsActivityRedux = {
  createCases,
  getUrl,
}
