import { Divider, LinkButton } from '@creditonebank/identity-ui'
import { v3 } from '@creditonebank/identity-ui/dist/theme-utils'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { FC } from 'react'

const StyledContainer = styled(motion.div)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${v3.primativeUnits.ref_size_size_4};
  hr{
    box-sizing: border-box;
  }
`

const FooterContainer = styled.div`
  padding: ${v3.primativeUnits.ref_size_size_10} ${v3.primativeUnits.ref_size_size_24} 22px;
`

/**
 * Container for the footer of the FlyOut Menu
 */
export const BottomContainer: FC = () => {
  return (
    <StyledContainer
      key="bottom"
      variants={{
        open: {
          opacity: 1,
        },
        closed: {
          opacity: 0,
        },
      }}
    >
      <Divider margin={8} />
      <FooterContainer>
        <LinkButton
          text="Help"
          onClick={() => {
            alert('Going to help page')
          }}
          iconTypeLeft="Add"
          padding={false}
        />
      </FooterContainer>
    </StyledContainer>
  )
}
