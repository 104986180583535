import { type PutCustomersSecurityQuestions } from './put-customers-security-questions'
import { GetIdpSecurityQuestions } from '../get-idp-security-questions/get-idp-security-questions'

/**
 * Returns the url depending on the request
 * @param _request - Request of the PutCustomerSecurityQuestions
 */
const getUrl = (_request?: PutCustomersSecurityQuestions['APIRequest']): string => {
  // Create url to be returned to the query
  return '/customers/security-questions'
}

/**
 * Format type for what the UI will try to send.
 */

/**
 * Transforms the form into the request body depending on the get call
 * @param form - the questions and answers from the user
 * @param getCustomerSecurityQuestionsResponse - the get call that had the questions originally
 */
const transformRequestBody = (
  form: TransformRequestBody['form'],
  getCustomerSecurityQuestionsResponse: TransformRequestBody['original']): PutCustomersSecurityQuestions['APIRequest']['body'] => {
// Type out our return variable.
  const body: PutCustomersSecurityQuestions['APIRequest']['body'] = []

  // Define the keys to be expected from the UI.
  const questionIds = [
    {
      q: 'sec-q-1-select',
      a: 'sec-q-1-answer',
    },
    {
      q: 'sec-q-2-select',
      a: 'sec-q-2-answer',
    },
    {
      q: 'sec-q-3-select',
      a: 'sec-q-3-answer',
    },
  ]

  // For each field returned by the UI, find its counterpart in the original SecurityQuestionsResponse.
  // Package that original SecurityQuestionsAnswer into the body.
  questionIds.forEach((e) => {
    const question = getCustomerSecurityQuestionsResponse
      .find(f => f.text === form[e.q as keyof typeof form])

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const element: PutCustomersSecurityQuestions['APIRequest']['body'][number] = {
      ...question,
      answer: form[e.a as keyof typeof form],
    } as PutCustomersSecurityQuestions['APIRequest']['body'][number]

    body.push(element)
  })

  // Return the PUT body.
  return body
}

/**
 * This is the objects that get sent into `putCustomerSecurityQuestionsRedux.transformRequest` to transform them into {@link PutCustomersSecurityQuestions.APIRequest}
 */
interface TransformRequestBody {
  /**
   * The form values.
   */
  form: {
    'sec-q-1-answer': string
    'sec-q-1-select': string
    'sec-q-2-answer': string
    'sec-q-2-select': string
    'sec-q-3-answer': string
    'sec-q-3-select': string
  }
  /**
   * The original {@link  GetCustomerSecurityQuestions.APISuccessResponse}
   */
  original: GetIdpSecurityQuestions['APISuccessResponse']
}
export interface PutCustomersSecurityQuestionsRedux {
  /**
   * This is the objects that get sent into `putCustomerSecurityQuestionsRedux.transformRequest` to transform them into {@link PutCustomersSecurityQuestions.APIRequest}
   *
   */
  transformedRequest: TransformRequestBody
}
export const putCustomersSecurityQuestionsRedux = {
  getUrl,
  transformRequestBody,
}
