//* This is generated. DO NOT TOUCH
/* eslint-disable */
import { type FetchArgs } from '@reduxjs/toolkit/query'

import { CardLostStolenResponse} from '@/apis/models/SwaggerTypes'

import { HTTPMethod, type Builder, type QueryThunk, type ErrorResponse, type BaseRTKInterface } from '../../types/apis.types'
import { getAccountsCardLostStolenRedux } from './get-accounts-card-lost-stolen-redux'


export interface APIRequest {
	path: {
		accountId: string
	},


}

type StatusCodes = 401 | 403 | 404 | 500
type APIErrorResponse = ErrorResponse<StatusCodes>

type APISuccessResponse = CardLostStolenResponse
type APIResponse = APISuccessResponse | APIErrorResponse
const endpointName = 'getAccountsCardLostStolen' as const


/**
 * This is the query function for what we want to call.
 * @param request - The ThunkRequest aka HookRequest
 * @returns - The query request
 */
const query = (request: APIRequest): FetchArgs => ({
  url: getAccountsCardLostStolenRedux.getUrl(request,),
  method: HTTPMethod.GET,
})


/**
 * This is a function to call if you want to do a query (GET)
 * YOU PROBABLY DON'T NEED TO CHANGE THIS FUNCTION
 * @param builder - Creates the thunk for the apis file to be used as a hook
 */
const thunk = (builder: Builder): QueryThunk<APIRequest, APIResponse> => {
  return builder.query<APIResponse, APIRequest>({
    query,
  })
}

export interface GetAccountsCardLostStolen extends BaseRTKInterface {
  StatusCodes: StatusCodes
  APIErrorResponse: APIErrorResponse
  APISuccessResponse: APISuccessResponse
  APIRequest: APIRequest
  APIResponse: APIResponse
  APISuccessStatusCode: 200
}

export const getAccountsCardLostStolen = {
  endpointName,
  thunk
}
