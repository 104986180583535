import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { GetBanks } from './get-banks'

import { AccountsState } from '@/redux/reducers/accounts/accounts'

// Keys should only be changed here
// interface ReduxResponse {
//   bankName: string
// }

/**
 * Returns the url depending on the request
 * @param request - Request of the API
 */
const getUrl = (request?: GetBanks['APIRequest']): string => {
  // Create url to be returned to the query
  return `/banks/${request?.path.bankRoutingNumber}`
}

/**
 * Utility function that creates reducer cases for the API call.
 * YOU PROBABLY DON'T NEED TO CHANGE THIS
 * @param _builder - The reducer builder.
 */
// eslint-disable-next-line sonarjs/no-empty-function
const createCases = (_builder: ActionReducerMapBuilder<AccountsState>): void => {}

export const getBanksRedux = {
  createCases,
  getUrl,
}
