import { PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { APIRequest, putCustomersIncome, PutCustomersIncome } from './put-customers-income'
import { api } from '../../api'

import { CustomerState } from '@/redux/reducers/customer/customer'
import { truncateCurrency } from '@/shared/utils/number-utils'

// Keys should only be changed here
// interface ReduxResponse {
//   type: string
//   value: string
// }

/**
 * Returns the url depending on the request
 * @param _request - Request of the API
 */
const getUrl = (_request?: PutCustomersIncome['APIRequest']): string => {
  // Create url to be returned to the query
  return '/customers/income'
}

interface Meta {
  arg: {
    endpointName: string
    originalArgs: APIRequest
  }
  baseMutationMeta: {
    request: {
      body: APIRequest['body']
    }
  }
}

/**
 * What to do when the API was successful.
 * THIS WILL UPDATE THE SliceState
 * @param _state - The current state of the slice.
 * @param _action - The action containing the successful response.
 */
const handleFulfilled = (_state: CustomerState, _action: PayloadAction<PutCustomersIncome['APISuccessResponse'], string, Meta>): void => {
  const amount = Number(_action.meta.baseMutationMeta.request.body.amount)
  const frequency = _action.meta.baseMutationMeta.request.body.frequency
  let annual: number
  let monthly: number
  let weekly: number
  switch (frequency) {
    case 'Monthly':{
      annual = truncateCurrency(amount * 12)
      monthly = amount
      weekly = truncateCurrency(amount / 4)
      break
    }
    case 'Weekly':{
      annual = truncateCurrency(amount * 52)
      monthly = truncateCurrency(amount * 4)
      weekly = amount
      break
    }
    // Annual
    case 'Anual': {
      annual = amount
      monthly = truncateCurrency(amount / 12)
      weekly = truncateCurrency(amount / 52)
      break
    }
    default:
      annual = 0
      weekly = 0
      monthly = 0
      break
  }

  _state.income = { annual, monthly, weekly }
}

/**
 * What to do if the API failed (rejected)
 * @param _state - The current state of the account.
 * @param _action - The action containing the error response.
 */
const handleRejected = (_state: CustomerState, _action: PayloadAction<PutCustomersIncome['APIErrorResponse']>): void => {
  // Do something to the StateSlice when something fails. Most of the time we won't need something like this because we'll return the failure response from the API call itself. But if we need to update state here we can.
}

/**
 * Utility function that creates reducer cases for the API call.
 * YOU PROBABLY DON'T NEED TO CHANGE THIS
 * @param builder - The reducer builder.
 */
const createCases = (builder: ActionReducerMapBuilder<CustomerState>): void => {
  const endpoint = api.endpoints[putCustomersIncome.endpointName]
  builder.addMatcher(endpoint.matchFulfilled, (state, action) => {
    handleFulfilled(state, action as unknown as PayloadAction<PutCustomersIncome['APISuccessResponse'], string, Meta>)
  })
  builder.addMatcher(endpoint.matchRejected, (state, action) => {
    handleRejected(state, action as unknown as PayloadAction<PutCustomersIncome['APIErrorResponse']>)
  })
}

export const putCustomersIncomeRedux = {
  createCases,
  getUrl,
}
