import { LinkProps } from '@creditonebank/identity-ui'
import { v3 } from '@creditonebank/identity-ui/dist/theme-utils'
import styled from '@emotion/styled'
import { FC } from 'react'
import { Link } from 'react-router-dom'

const StyledLink = styled.div`

  display: flex;
  position: relative;
  cursor: pointer;
  font-family: ${v3.fontFamily};
  padding: 0;

  &.padding-applied{
    padding: 2px 4px;
  }

  &.left-icon{
    > .link-component{
      padding: 0 0 0 4px
    }
  }

  &.right-icon{
    > .link-component{
      padding: 0 4px 0 0
    }
  }

  &.right-icon.left-icon,
  &.padding-applied{
    > .link-component{
      padding: 0 4px
    }
  }

  > .link-component {
    display: inherit;
    text-decoration: none;
    outline: none;
  }

  > * {
    align-self:center;
  }

  /* props.size === 'l' */
  &.l {
    font-size: ${v3.semanticStyles.typography_fontsize_heading_sm};
    line-height: ${v3.semanticStyles.typography_lineheight_heading_sm};
    font-weight: ${v3.semanticStyles.typography_fontweight_semi_bold};
  }

  /* props.size === 'm' */
  &.m {
    font-size: ${v3.semanticStyles.typography_fontsize_body_lg};
    line-height:  ${v3.semanticStyles.typography_lineheight_body_lg};
    font-weight: ${v3.semanticStyles.typography_fontweight_regular};
  }

  /* props.size === 's' */
  &.s {
    font-size:  ${v3.semanticStyles.typography_fontsize_body_md};
    line-height: ${v3.semanticStyles.typography_lineheight_body_md};
    font-weight: ${v3.semanticStyles.typography_fontweight_regular};
  }

  /* props.size === 'xs' */
  &.xs{
    font-size: ${v3.semanticStyles.typography_fontsize_body_sm};
    line-height: ${v3.semanticStyles.typography_lineheight_body_sm};
    font-weight: ${v3.semanticStyles.typography_fontweight_regular};
  }

  &[aria-disabled="false"]:has(*:focus-visible) {
    outline-style: solid;
    outline-width: 2px;
    outline-offset: 2px;
    border-radius: 4px;
  }

  &[aria-disabled="true"] {
    outline: none
  }

  &:hover:not([aria-disabled="true"], .has-icon) {
    .link-component {
      text-decoration: underline !important;
    }
  }

  /** props.mode === 'light' */
  &.light {
    .link-component, svg path {
      color: ${v3.semanticColors.link_text_primary};
      fill: ${v3.semanticColors.link_text_primary};
    }

    /** When the link is disabled */
    &[aria-disabled="true"], &[aria-disabled="true"]:hover {
      cursor: not-allowed;

      .link-component, svg path {
        cursor: not-allowed;
        color: ${v3.semanticColors.link_text_primary__disabled};
        fill: ${v3.semanticColors.link_text_primary__disabled};
      }
    }

    /** When the link is focused */
    &:focus-within {
      outline-color: ${v3.semanticColors.link_border_primary__focus};
    }

    /** When the link it hovered */
    &:hover:not(:active, [aria-disabled="true"]) {
      .link-component, svg path {
        color: ${v3.semanticColors.link_text_primary__hover};
        fill: ${v3.semanticColors.link_text_primary__hover};
        text-decoration-color: ${v3.semanticColors.link_text_primary__hover};
      }
    }
  }

`
export const NavLink: FC<LinkProps> = ({ text, href }) => {
  return <StyledLink><Link className="link-component" to={href ?? '/'}>{text}</Link></StyledLink>
}
