import { v3 } from '@creditonebank/identity-ui/dist/theme-utils'
import styled from '@emotion/styled'
import { FC } from 'react'

export interface MenuButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  /**
   * If the button is encapsulated.
   * @default true
   */
  encapsulated?: boolean
  /**
   * If the border should be displayed.
   * @default true
   */
  border?: boolean
  /**
   * Whether the header should have light style
   * @default true
   */
  lightEnabled?: boolean
  /**
   * If the menu button should be in open state (close button).
   * @default false
   */
  isOpen?: boolean
}

const StyledIconButton = styled.button<{
  encapsulated: boolean
  border: boolean
  lightEnabled: boolean
}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 60px;
  background: ${(props: { encapsulated: boolean }) => props?.encapsulated ? v3.semanticColors.surface_bg_secondary : 'none'};
  border: ${props => props.border ? `1px solid ${v3.semanticColors.button_border_secondary}` : 'none'};
  cursor: pointer;

  /* Remove light blue highlighting when tapping element on mobile  */
  -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  &:not(.l, .s){
    width: 24px;
    height: 24px;
  }

  &:focus-visible{
    outline-offset: ${v3.focus.outlineOffset};
    outline: ${v3.focus.outline};
  }

  #menu-icon {
    width: 20px;
    height: 18px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    & > span {
      display: flex;
      position: absolute;
      height: 2px;
      width: 100%;
      background:${(props: { lightEnabled: boolean }) => props?.lightEnabled ? v3.semanticColors.button_icon_tertiary : v3.semanticColors.button_icon_primary};
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 2px;
      }

      &:nth-child(2), &:nth-child(3) {
        top: 8px;
      }

      &:nth-child(4) {
        top: 14px;
      }
    }

    &.open > span {
      background: ${v3.semanticColors.button_icon_tertiary};

      &:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 8px;
        width: 0%;
        left: 50%;
      }
    }
  }
`
/**
 * Custom button component for displaying and animating between the menu and and close icons.
 * @param props - {@link MenuButtonProps}
 */
export const MenuButton: FC<MenuButtonProps> = ({
  encapsulated = true,
  border = true,
  lightEnabled = true,
  isOpen = false,
  onClick, ...props
}) => {
  return (
    <StyledIconButton
      encapsulated={encapsulated}
      border={border}
      lightEnabled={lightEnabled}
      onClick={onClick}
      {...props}
    >
      <div id="menu-icon" className={`${isOpen ? 'open' : ''}`}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </StyledIconButton>
  )
}
