import { v3 } from '@creditonebank/identity-ui/dist/theme-utils'
import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import { FC, useState } from 'react'

import { BottomContainer } from './features/bottom-container'
import { Header } from './features/header'
import { Links } from './features/links'
import platinumCard from '../../../assets/platinum-orange-card.png'
import wanderCard from '../../../assets/wander-card.png'
import x5Card from '../../../assets/x5-card.svg'

import { useGetCustomersAccountsQuery } from '@/redux/apis/api'
import { useFoundAccount } from '@/shared/hooks/useFoundAccount'

const HeaderContainer = styled.div`
  background-color: #002752;
  padding: ${v3.primativeUnits.ref_size_size_8} 0;
  grid-area: header / gutter;
  width: 100%;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  &.white{
    background-color: ${v3.semanticColors.surface_bg_secondary};
  }
`
const MenuOpenContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 60px;
  z-index: 998;
  right: 8px;
  position: absolute;
  background-color: ${v3.semanticColors.surface_bg_secondary};
  border-radius: ${v3.primativeUnits.ref_size_size_8};

    .constantHeader{
      position: absolute
    }

`
const MenuContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  height: 60px;
`
const StyledOverlay = styled(motion.div)`
background: #0006;
position: fixed;
inset: 0;
z-index: 997;
`

/**
 * Shows account's card, title, and subtitle with navigational menu for mobile
 */
export const FlyoutMenu: FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [lightBackground] = useState(false)
  useGetCustomersAccountsQuery(undefined)
  const account = useFoundAccount()
  if (account === null) {
    return null
  }

  let cardSrc = ''
  let altText = ''
  switch (account.productType) {
    case 'Wander':
      cardSrc = wanderCard
      altText = 'Wander Card'
      break
    case 'X5':
      cardSrc = x5Card
      altText = 'X5 Card'
      break
    default:
      cardSrc = platinumCard
      altText = 'Platinum Card'
  }

  return (
    <HeaderContainer className={lightBackground ? 'white' : ''} id="mobile-header">
      <Header
        menuOpen={isOpen}
        setMenuOpen={setIsOpen}
        cardPicture={cardSrc}
        title={account.productType}
        subtitle={`${account.cardType} ${account.device.last4Digit}`}
        lightEnabled={lightBackground}
        altText={altText}
      />
      <AnimatePresence mode="wait" initial={false}>
        {isOpen && (
          <StyledOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.6 }}
            exit={{ opacity: 0 }}
            key="overlay"
            className="overlay"
            onClick={() => {
              setIsOpen(false)
            }}
          />
        )}
        {isOpen && (
          <MenuOpenContainer
            layoutId="header"
            key="menu"
            variants={{
              open: {
                height: [null, '0%', 'auto'],
                left: ['calc(100% - 8px)', 'calc(0% - 8px)'],
                right: '-8px',
                transition: {
                  when: 'beforeChildren',
                  duration: 0.1,
                  staggerChildren: 0.05,
                },
              },
              closed: {
                left: '100%',
                right: '0px',
              },
            }}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{
              duration: 0.3,
              ease: 'easeInOut',

            }}
          >

            <Links />

            <BottomContainer />

          </MenuOpenContainer>

        )}
      </AnimatePresence>
      <MenuContainer
        layoutId="header"
        key="closed_header"
      />
    </HeaderContainer>
  )
}
