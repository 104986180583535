import { type ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { APIRequest, getCustomersAccounts, type GetCustomersAccounts } from './get-customers-accounts'
import { api } from '../../api'

import { initAccount, type AccountsState } from '@/redux/reducers/accounts/accounts'
import { Account } from '@/redux/reducers/accounts/types'
import { CustomerState } from '@/redux/reducers/customer/customer'

type AccountResponse = Omit<Required<Account>, 'isCardLocked' | 'replacementCard' | 'isAutoPayEnabled' | 'cardShipment' | 'productType' | 'cardType' | 'scheduledPaymentsCount' | 'accountAlertBanner' | 'last4Digits' | 'hasCardActivationAttemptsRemaining' | 'pinEligibility' | 'rewardsSummary' | 'paymentAutoPay' >
type ReduxResponse = AccountResponse[]

interface Meta {
  arg: {
    endpointName: string
    originalArgs: APIRequest
  }

}

/**
 * Returns the url depending on the request
 * @param _request - Request of the API
 */
const getUrl = (_request?: GetCustomersAccounts['APIRequest']): string => {
  // Create url to be returned to the query
  return `/customers/accounts`
}

/**
 * Transform the response to be used within redux
 * @param response - response from the API
 */
const transformResponseToReduxResponse = (response: GetCustomersAccounts['APISuccessResponse']): ReduxResponse => {
  const returnObject: AccountResponse[] = []
  response.forEach((res) => {
    returnObject.push({
      id: res.accountId ?? '',
      balance: {
        balance: res.balance?.balance ?? -1,
        creditLimit: res.balance?.creditLimit ?? -1,
        creditLimitForCash: res.balance?.creditLimitForCash ?? -1,
        availableCredit: res.balance?.availableCredit ?? -1,
        totalAvailableCreditForCash: res.balance?.totalAvailableCredit ?? -1,
        totalAvailableCredit: res.balance?.totalAvailableCredit ?? -1,
        minimumPayment: res.balance?.minimumPayment ?? -1,
        hasMinimumPaymentMet: res.balance?.hasMinimumPaymentMet ?? false,
        paymentDueDate: res.balance?.paymentDueDate ?? '',
        isPastDue: res.balance?.isPastDue ?? false,
        isDelinquent: res.balance?.isDelinquent ?? false,
        daysDelinquent: res.balance?.daysDelinquent ?? -1,
        lastStatementBalance: res.balance?.lastStatementBalance ?? -1,
        lastPaymentAmount: res.balance?.lastPaymentAmount ?? -1,
        totalPaymentsAndCredits: res.balance?.totalPaymentsAndCredits ?? -1,
        availableCreditForCash: res.balance?.availableCreditForCash ?? -1,
        totalTransactions: res.balance?.totalTransactions ?? -1,
      },
      device: {
        last4Digit: res.device?.last4Digit ?? '',
        network: res.device?.network ?? '',
        status: res.device?.status ?? '',
        expiryDate: res.device?.expiryDate ?? '',
        isActivated: res.device?.isActivated ?? false,
        lastPlasticDate: res.device?.lastPlasticDate ?? -1,
        currentPlasticDesignCode: res.device?.currentPlasticDesignCode ?? '',
      },
      involvementId: res.involvementId ?? '',
      isSecuredCard: res.isSecuredCard ?? false,
      name: res.name ?? '',
      productKey: res.productKey ?? '',
      savingAccountNumber: res.savingAccountNumber ?? '',
      paymentHistory: [],
      paymentPending: [],
      isActivated: false,
      isCardActivationEligible: false,
    })
  })
  return returnObject
}

/**
 * What to do when the API was successful.
 * THIS WILL UPDATE THE SliceState
 * @param state - The current state of the slice.
 * @param action - The action containing the successful response.
 */
const handleFulfilled = (state: AccountsState, action: PayloadAction<GetCustomersAccounts['APISuccessResponse'], string, Meta>): void => {
  const transformedResponse: ReduxResponse = transformResponseToReduxResponse(action.payload)
  // Do something with the transformed state in the slice
  if (state.length === 0) {
    const newList: Account[] = []
    transformedResponse.forEach((account) => {
      {
        const newAccount = initAccount(account)

        newList.push(newAccount)
      }
    })
    state.push(...newList)
  }
  else {
    const newList: Account[] = []
    transformedResponse.forEach((res) => {
      const existingAccount = state.find(account => account.id === res.id)
      if (existingAccount !== undefined) {
        const updatedAccount: Account = { ...existingAccount, ...res }
        newList.push(updatedAccount)
      }
      else {
        const newAccount = initAccount(res)
        newList.push(newAccount)
      }
    })
    state.push(...newList)
  }
}

/**
 * What to do if the API failed (rejected)
 * @param _state - The current state of the account.
 * @param _action - The action containing the error response.
 */
const handleRejected = (_state: AccountsState, _action: PayloadAction<GetCustomersAccounts['APIErrorResponse']>): void => {
  // Do something to the StateSlice when something fails. Most of the time we won't need something like this because we'll return the failure response from the API call itself. But if we need to update state here we can.
}

/**
 * Utility function that creates reducer cases for the API call.
 * YOU PROBABLY DON'T NEED TO CHANGE THIS
 * @param builder - The reducer builder.
 */
const createCasesAccount = (builder: ActionReducerMapBuilder<AccountsState>): void => {
  const endpoint = api.endpoints[getCustomersAccounts.endpointName]
  builder.addMatcher(endpoint.matchFulfilled, (state, action) => {
    handleFulfilled(state, action as PayloadAction<GetCustomersAccounts['APISuccessResponse'], string, Meta>)
  })
  builder.addMatcher(endpoint.matchRejected, (state, action) => {
    handleRejected(state, action as unknown as PayloadAction<GetCustomersAccounts['APIErrorResponse']>)
  })
}

/**
 * What to do when the API was successful.
 * THIS WILL UPDATE THE SliceState
 * @param state - The current state of the slice.
 * @param action - The action containing the successful response.
 */
const handleFulfilledCustomer = (state: CustomerState, action: PayloadAction<GetCustomersAccounts['APISuccessResponse'], string, Meta>): void => {
  if (action.payload.length > 0) {
    state.fullName = action.payload[0].name?.split(' ').map((e, i) => {
      if (i !== 0) {
        return e.substring(0, 1) + '.'
      }
      else { return e.substring(0, 1) + e.substring(1).toLowerCase() }
    },
    ).join(' ') ?? 'John Doe'
  }
}

/**
 * What to do if the API failed (rejected)
 * @param _state - The current state of the account.
 * @param _action - The action containing the error response.
 */
const handleRejectedCustomer = (_state: CustomerState, _action: PayloadAction<GetCustomersAccounts['APIErrorResponse']>): void => {
  // Do something to the StateSlice when something fails. Most of the time we won't need something like this because we'll return the failure response from the API call itself. But if we need to update state here we can.
}

/**
 * Utility function that creates reducer cases for the API call.
 * YOU PROBABLY DON'T NEED TO CHANGE THIS
 * @param builder - The reducer builder.
 */
const createCasesCustomer = (builder: ActionReducerMapBuilder<CustomerState>): void => {
  const endpoint = api.endpoints[getCustomersAccounts.endpointName]
  builder.addMatcher(endpoint.matchFulfilled, (state, action) => {
    handleFulfilledCustomer(state, action as PayloadAction<GetCustomersAccounts['APISuccessResponse'], string, Meta>)
  })
  builder.addMatcher(endpoint.matchRejected, (state, action) => {
    handleRejectedCustomer(state, action as unknown as PayloadAction<GetCustomersAccounts['APIErrorResponse']>)
  })
}

export const getCustomersAccountsRedux = {
  createCasesAccount,
  createCasesCustomer,
  getUrl,
}
