import { IconButton } from '@creditonebank/identity-ui'
import { v3 } from '@creditonebank/identity-ui/dist/theme-utils'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { FC, useRef } from 'react'

import { MenuButton } from './menu-button'

export interface HeaderProps extends React.HtmlHTMLAttributes<HTMLDivElement> {

  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  /**
   * Shows when the menu is open
   */
  menuOpen: boolean
  /**
   * The src for the car picture
   */
  cardPicture: string
  /**
   * The title of the account
   */
  title: string
  /**
   * The product type and last4 of the account
   */
  subtitle: string
  /**
   *
   * @default false
   */
  backEnabled?: boolean
  /**
   * Whether the header should have light style
   * @default true
   */
  lightEnabled?: boolean

  /**
   * Color the hamburger when the menu is closed.
   * @default v3.semanticColors.surface_bg_secondary
   */
  hamburgerColor?: string
  /**
   * Alt text for the card image
   */
  altText: string
}

const StyledHeaderContainer = styled(motion.div)`
  top: 10px;
  z-index: 999;
  left: 0px;
  right: 0px;
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: ${v3.primativeUnits.ref_size_size_12} 0 ${v3.primativeUnits.ref_size_size_12} ${v3.primativeUnits.ref_size_size_8};
  gap: ${v3.primativeUnits.ref_size_size_12};
  justify-content: space-between;
  box-sizing: border-box;
`
const AccountHeader = styled(motion.span)`
  font-weight: ${v3.semanticStyles.typography_fontweight_semi_bold};
  font-size: ${v3.semanticStyles.typography_fontsize_body_md};
  line-height: ${v3.semanticStyles.typography_lineheight_body_md};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const AccountSubtitle = styled(motion.span)`
  font-size: ${v3.semanticStyles.typography_fontsize_body_sm};
  line-height: ${v3.semanticStyles.typography_lineheight_body_sm};
`

const HamburgerButton = styled.div<{ hamburgerColor: string }>`
  border-radius: ${v3.primativeUnits.ref_size_size_8};
  background-color: ${props => props.hamburgerColor};
  box-sizing: border-box;
  height: fit-content;
  padding: 8px;
  &.light{
    border: 1px solid ${v3.semanticColors.border_primary};
    &.open {
        border: none;
    }
    &.closed {
      transition-delay: .3s;
      padding: 7px;
    }
    svg path{
      fill: ${v3.semanticColors.button_icon_tertiary} ;
    }
  }
  &.dark{
      background-color: #234469;
      &.open {
        background-color: transparent;
      }
      &.closed {
        transition-delay: .3s;
      }
    svg path{
      fill: ${v3.semanticColors.button_icon_primary};
    }
    &:hover{
      svg path{
        fill: ${v3.semanticColors.button_icon_primary};
      }
    }

  }
`
const StyledCardImage = styled.img`
  width: 51px;
  height: 32px;
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${v3.primativeUnits.ref_size_size_8};
  justify-content: space-between;
  align-items: center;
  &.dark{
    > svg path{
      fill: ${v3.semanticColors.button_icon_primary};
    }
  }
  overflow: hidden;
`

/**
 * Header for the flyout menu component
 * @param props - {@link HeaderProps}
 */
export const Header: FC<HeaderProps> = (
  {
    setMenuOpen,
    menuOpen,
    cardPicture,
    title,
    subtitle,
    backEnabled = false,
    lightEnabled = true,
    hamburgerColor = v3.semanticColors.surface_bg_secondary,
    altText,
  }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  return (
    <StyledHeaderContainer ref={containerRef}>
      <StyledCardContainer className={lightEnabled ? 'light' : 'dark'}>
        {backEnabled && (
          <IconButton icon={{ name: 'ArrowBack' }} ariaLabel="Previous Page" encapsulated={false} border={false} style={{ marginRight: v3.primativeUnits.ref_size_size_8 }} />
        )}
        <StyledCardImage
          src={cardPicture}
          alt={altText}
        />
        <TextContainer>
          <AccountHeader
            variants={{
              closed: {
                color: `${v3.semanticColors.heading_primary}`,
              },
              open: {
                color: `${lightEnabled ? v3.semanticColors.heading_primary : v3.semanticColors.text_link_secondary}`,
              },
            }}
            animate={menuOpen ? 'closed' : 'open'}
            transition={{
              duration: 0.3,
              ease: 'easeInOut',
              times: [0, 0.5, 1],
            }}
          >
            {title}
          </AccountHeader>
          <AccountSubtitle
            variants={{
              closed: {
                color: `${v3.semanticColors.text_secondary}`,
              },
              open: {
                color: `${lightEnabled ? v3.semanticColors.text_secondary : v3.semanticColors.text_link_secondary}`,
              },
            }}
            animate={menuOpen ? 'closed' : 'open'}
            transition={{
              duration: 0.3,
              ease: 'easeInOut',
            }}
          >
            {subtitle}
          </AccountSubtitle>
        </TextContainer>
      </StyledCardContainer>
      <HamburgerButton hamburgerColor={menuOpen ? hamburgerColor : 'transparent'} className={`${menuOpen ? 'open' : 'closed'} ${lightEnabled ? 'light' : 'dark'}`}>
        <MenuButton
          encapsulated={false}
          border={false}
          isOpen={menuOpen}
          lightEnabled={lightEnabled}
          onClick={() => setMenuOpen(!menuOpen)}
        />
      </HamburgerButton>
    </StyledHeaderContainer>
  )
}
