import { InteractableOption, InteractableOptionProps } from '@creditonebank/identity-ui/dist/components/menu/interactable-option/interactable-option'
import { v3 } from '@creditonebank/identity-ui/dist/theme-utils'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { FC } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0;

  button span {
    font-weight: ${v3.semanticStyles.typography_fontweight_semi_bold};
    padding-left: ${v3.primativeUnits.ref_size_size_8};
  }
`

const LinkDiv = styled(motion.div)`

`

/**
 * Links component displays a menu of link items on the Flyout Menu for navigating across the different account pages in mobile.
 * It uses the Menu's InteractableOption component to render each item in the list.
 * Each item represents a different page of the account,
 * These pages include account-overview, payments, activity & statements, manage card, benefits & rewards, offers
 * @example
 * return (
 *   <Links />
 * )
 */
export const Links: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { accountId } = useParams()

  const LinkItems: InteractableOptionProps[] = [
    {
      //  TODO: Update based on how many accounts are returned from the API -- https://creditonebank.atlassian.net/browse/SSN-1113
      whenClicked: () => { navigate(`/account/${accountId}`) },
      onClick: () => { navigate(`/account/${accountId}`) },
      label: 'Overview',
      isSelected: location.pathname.includes('/'),
      id: 'overview',
    },
    {
      whenClicked: () => { navigate('/payments') },
      onClick: () => { navigate('/payments') },
      label: 'Payments',
      isSelected: location.pathname.includes('/payments'),
      id: 'payments',
    },
    {
      whenClicked: () => { navigate('/activity-and-statements') },
      onClick: () => { navigate('/activity-and-statements') },
      label: 'Activity & Statements',
      isSelected: location.pathname.includes('/activity-and-statements'),
      id: 'activity-and-statements',
    },
    {
      whenClicked: () => { navigate('/manage-card') },
      onClick: () => { navigate('/manage-card') },
      isSelected: location.pathname.includes('/manage-card'),
      label: 'Manage Card',
      id: 'manage-card',
    },
    {
      whenClicked: () => { navigate('/benefits-and-rewards') },
      onClick: () => { navigate('/benefits-and-rewards') },
      isSelected: location.pathname.includes('/benefits-and-rewards'),
      label: 'Benefits & Rewards',
      id: 'benefits',
    },
    {
      whenClicked: () => { navigate('/offers') },
      onClick: () => { navigate('/offers') },
      isSelected: location.pathname.includes('/offers'),
      label: 'Offers',
      id: 'offers',
    },
  ]

  return (
    <StyledContainer>
      {
        LinkItems.map((LinkItems) => {
          return (
            <LinkDiv
              key={LinkItems.label}
              variants={{
                open: {
                  opacity: 1,
                  y: 0,
                },
                closed: {
                  opacity: 0,
                  y: -15,
                },
              }}
            >
              <InteractableOption
                {...LinkItems}
                checkmarkPosition="nextToText"
              />
            </LinkDiv>
          )
        })
      }
    </StyledContainer>
  )
}
