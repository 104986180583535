const config = {
  auth: {
    devMode: false, // TODO: Set config here to get verbose information.
    issuer: '',
    clientId: '',
    redirectUri: '',
    postLogoutRedirectUri: '',
    scopes: ['openid', 'email', 'customer', 'profile'],
  },
  apiCacheSeconds: import.meta.env.VITE_API_CACHE_SECONDS ?? 60,
  contentVariables: {
    CUSTOMER_SERVICE_NUMBER: '877-825-3242',
  },
  rtk: {
    bffBasePath: '/public/creditcard/',
  },
  adobe: {
    url: '',
  },
}

export default config
