import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { RootState } from '@/redux/store'

/**
 * Returns the account information associated with a specific `accountId` from the `useParams` hook.
 * While API work is being done, this return mock data if no account is found.
 */
export const useFoundAccount = () => {
  const { accountId } = useParams()
  return useSelector((state: RootState) => {
    const foundAccount = state.AccountsReducer.find(({ id }) => id === accountId)
    if (foundAccount) return foundAccount

    return null
  })
}
