import { Balance } from './balance.types'
import { ReplacementCard, CardShipment } from './card.types'
import { Device } from './device.types'
import { PaymentList, PaymentAutoPay } from './payment.types'
import { RewardsSummary } from './rewards.types'

export interface Account {
  /**
   * Id of the account
   */
  id: string
  /**
   * Name of the account
   */
  name: string
  /**
   * Product name of the account
   */
  productKey: string
  /**
   * Whether the account's card is a secured card
   */
  isSecuredCard: boolean
  /**
   * Used to tie account to customer information
   */
  involvementId: string
  /**
   * Saving account tied to account
   */
  savingAccountNumber: string
  /**
   * Card for the account - {@link Device}
   */
  device: Device
  /**
   * Balance of the account - {@link Balance}
   */
  balance: Balance
  /**
   * If there is any more times the activation for the card can be attempted
   */
  hasCardActivationAttemptsRemaining?: boolean
  /**
   * If the card is locked for the account
   */
  isCardLocked: boolean
  /**
   * Replacement card for the account - {@link ReplacementCard}
   */
  replacementCard?: ReplacementCard
  /**
   * Details of card shipment for account - {@link CardShipment}
   */
  cardShipment?: CardShipment
  /**
   * What type product the account is - {@link productTypes}
   */
  productType: productTypes
  /**
   * What type of card for the account - {@link cardTypes}
   */
  cardType: cardTypes
  /**
   * If autopay was turn on for account
   */
  isAutoPayEnabled: boolean
  /**
   * Number of scheduled payments for account
   */
  scheduledPaymentsCount: number
  /**
   * Alerts for account banners - {@link AccountAlertBanner}
   */
  accountAlertBanner: AccountAlertBanner[]
  /**
   * If the pin can be changed for account
   */
  pinEligibility: boolean
  /**
   * Payments that have been processed for account - {@link PaymentList}
   */
  paymentHistory: PaymentList
  /**
   * Payments that are pending for account - {@link PaymentList}
   */
  paymentPending: PaymentList
  /**
   * If the card has been activated for account
   */
  isActivated: boolean
  /**
   * If the card can be activated for account
   */
  isCardActivationEligible: boolean
  /**
   * Rewards for account - {@link RewardsSummary}
   */
  rewardsSummary?: RewardsSummary
  /**
   * Autopay settings for account - {@link PaymentAutoPay}
   */
  paymentAutoPay?: PaymentAutoPay
}

export interface AccountAlertBanner {
  message: string
  type: 'informational' | 'warning' | 'error'
  link: string
  icon: 'AlertInformation' | 'AlertWarning' | 'AlertError'
}

/**
 * Types that any account can be
 */
export enum productTypes {
  WANDER = 'Wander',
  PLATINUM = 'Platinum',
  X5 = 'X5',
}

/**
 * Types of card for an account
 */
export enum cardTypes {
  VISA = 'Visa',
  AMEX = 'Amex',
}
