import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { APIRequest, postCustomersFundingSourcesBankAccountsYodleeVerification, PostCustomersFundingSourcesBankAccountsYodleeVerification } from './post-customers-funding-sources-bank-accounts-yodlee-verification'
import { api } from '../../api'

import { ExternalAccountLinkingState } from '@/redux/reducers/external-account-linking/external-account-linking'

// Keys should only be changed here
interface ReduxResponse {
  yodleeToken: {
    accessToken: string
    issuedAt: string
    expiresIn: number
  }
}

/**
 * Returns the url depending on the request
 * @param request - Request of the API
 */
const getUrl = (request?: PostCustomersFundingSourcesBankAccountsYodleeVerification['APIRequest']): string => {
  // Create url to be returned to the query
  return `/customers/funding-sources/bank-accounts/${request?.path.bankAccountId}/yodlee-verification`
}

interface Meta {
  arg: {
    endpointName: string
    originalArgs: APIRequest
  }
  baseMutationMeta: {
    request: {
      body: APIRequest['body']
    }
  }
}

/**
 * Transform the response to be used within redux
 * @param response - response from the API
 */
const transformResponseToReduxResponse = (response: PostCustomersFundingSourcesBankAccountsYodleeVerification['APISuccessResponse']): ReduxResponse => {
  return {
    yodleeToken: {
      accessToken: response.yodleeToken?.accessToken ?? '',
      issuedAt: response.yodleeToken?.issuedAt ?? '',
      expiresIn: response.yodleeToken?.expiresIn ?? -1,
    },
  }
}

/**
 * What to do when the API was successful.
 * THIS WILL UPDATE THE SliceState
 * @param state - The current state of the slice.
 * @param _action - The action containing the successful response.
 */
const handleFulfilled = (state: ExternalAccountLinkingState, _action: PayloadAction<PostCustomersFundingSourcesBankAccountsYodleeVerification['APISuccessResponse'], string, Meta>): void => {
  const transformedResponse: ReduxResponse = transformResponseToReduxResponse(_action.payload)
  state.accountLinkingToken.accessToken = transformedResponse.yodleeToken.accessToken
  state.accountLinkingToken.issuedAt = transformedResponse.yodleeToken.issuedAt
  state.accountLinkingToken.expiresIn = transformedResponse.yodleeToken.expiresIn
}

/**
 * What to do if the API failed (rejected)
 * @param _state - The current state of the account.
 * @param _action - The action containing the error response.
 */
const handleRejected = (_state: ExternalAccountLinkingState, _action: PayloadAction<PostCustomersFundingSourcesBankAccountsYodleeVerification['APIErrorResponse']>): void => { /* TODO handle when the api is rejected */ }

/**
 * Utility function that creates reducer cases for the API call.
 * @param _builder - The reducer builder.
 */
const createCases = (_builder: ActionReducerMapBuilder<ExternalAccountLinkingState>): void => {
  const endpoint =
  api.endpoints[postCustomersFundingSourcesBankAccountsYodleeVerification.endpointName]
  _builder.addMatcher(endpoint.matchFulfilled, (state, action) => {
    handleFulfilled(state, action as unknown as PayloadAction<PostCustomersFundingSourcesBankAccountsYodleeVerification['APISuccessResponse'], string, Meta>)
  })
  _builder.addMatcher(endpoint.matchRejected, (state, action) => {
    handleRejected(state, action as unknown as PayloadAction<PostCustomersFundingSourcesBankAccountsYodleeVerification['APIErrorResponse']>)
  })
}

export const postCustomersFundingSourcesBankAccountsYodleeVerificationRedux = {
  createCases,
  getUrl,
}
