/**
 * Loads the scripts for adobe analytics
 */
export const loadAdobeScript = () => {
  if (process.env.NODE_ENV === 'development') {
    return
  }
  const script = document.createElement('script')
  script.async = true

  script.src = 'https://assets.adobedtm.com/b598760f96d3/7c72b447e495/launch-ba89eba38d4d-staging.min.js'

  document.head.appendChild(script)
}
