import { type ActionReducerMapBuilder, type PayloadAction } from '@reduxjs/toolkit'

import { putCustomersPassword, type PutCustomersPassword } from './put-customers-password'
import { api } from '../../api'

import { type CustomerState } from '@/redux/reducers/customer/customer'

/**
 * Returns the url depending on the request
 * @param _request - the request of the API
 */
const getUrl = (_request: PutCustomersPassword['APIRequest']): string => {
  // Create url to be returned to the query
  return '/customers/password'
}

/**
 * What to do when the API was successful.
 * THIS WILL UPDATE THE SliceState
 * @param _state - The current state of the slice.
 * @param _action - The action containing the successful response.
 */
const handleFulfilled = (_state: CustomerState, _action: PayloadAction<PutCustomersPassword['APISuccessResponse']>): void => {
  // Do something with the transformed state in the slice
}

/**
 * What to do if the API failed (rejected)
 * @param _state - The current state of the account.
 * @param _action - The action containing the error response.
 */
const handleRejected = (_state: CustomerState, _action: PayloadAction<PutCustomersPassword['APIErrorResponse']>): void => {
  // Do something to the StateSlice when something fails. Most of the time we won't need something like this because we'll return the failure response from the API call itself. But if we need to update state here we can.
}

/**
 * Utility function that creates reducer cases for the API call.
 * YOU PROBABLY DON'T NEED TO CHANGE THIS
 * @param builder - The reducer builder.
 */
const createCases = (builder: ActionReducerMapBuilder<CustomerState>): void => {
  const endpoint = api.endpoints[putCustomersPassword.endpointName]
  builder.addMatcher(endpoint.matchFulfilled, (state, action) => {
    handleFulfilled(state, action as PayloadAction<PutCustomersPassword['APISuccessResponse']>)
  })
  builder.addMatcher(endpoint.matchRejected, (state, action) => {
    handleRejected(state, action as unknown as PayloadAction<PutCustomersPassword['APIErrorResponse']>)
  })
}

export const putCustomersPasswordRedux = {
  createCases,
  getUrl,
}
