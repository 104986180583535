import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'

import { APIRequest, putCustomersFundingSourcesBankAccountsMicroDeposits, PutCustomersFundingSourcesBankAccountsMicroDeposits } from './put-customers-funding-sources-bank-accounts-micro-deposits'
import { api } from '../../api'

import { ExternalAccountLinkingState } from '@/redux/reducers/external-account-linking/external-account-linking'

// Keys should only be changed here
interface ReduxResponse {
  accountStatus: string
}

/**
 * Returns the url depending on the request
 * @param request - Request of the API
 */
const getUrl = (request?: PutCustomersFundingSourcesBankAccountsMicroDeposits['APIRequest']): string => {
  // Create url to be returned to the query
  return `/customers/funding-sources/bank-accounts/${request?.path.bankAccountId}/micro-deposits`
}

interface Meta {
  arg: {
    endpointName: string
    originalArgs: APIRequest
  }
  baseMutationMeta: {
    request: {
      body: APIRequest['body']
    }
  }
}

/**
 * Transform the response to be used within redux
 * @param response - response from the API
 */
const transformResponseToReduxResponse = (response: PutCustomersFundingSourcesBankAccountsMicroDeposits['APISuccessResponse']): ReduxResponse => {
  return {
    accountStatus: response.accountStatus ?? '',
  }
}

/**
 * What to do when the API was successful.
 * THIS WILL UPDATE THE SliceState
 * @param state - The current state of the slice.
 * @param action - The action containing the successful response.
 */
const handleFulfilled = (state: ExternalAccountLinkingState, action: PayloadAction<PutCustomersFundingSourcesBankAccountsMicroDeposits['APISuccessResponse'], string, Meta>): void => {
  const transformedResponse = transformResponseToReduxResponse(action.payload)
  const bankAccountId = action.meta.arg.originalArgs.path.bankAccountId
  const foundAccount = state.bankAccountList.find(account =>
    account.bankAccountId === bankAccountId)
  if (foundAccount) {
    foundAccount.status = transformedResponse.accountStatus
  }
}

/**
 * What to do if the API failed (rejected)
 * @param _state - The current state of the account.
 * @param _action - The action containing the error response.
 */
const handleRejected = (_state: ExternalAccountLinkingState, _action: PayloadAction<PutCustomersFundingSourcesBankAccountsMicroDeposits['APIErrorResponse']>): void => {
  // Do something to the StateSlice when something fails. Most of the time we won't need something like this because we'll return the failure response from the API call itself. But if we need to update state here we can.
}

/**
 * Utility function that creates reducer cases for the API call.
 * @param builder - The reducer builder.
 */
const createCases = (builder: ActionReducerMapBuilder<ExternalAccountLinkingState>): void => {
  const endpoint = api.endpoints[putCustomersFundingSourcesBankAccountsMicroDeposits.endpointName]
  builder.addMatcher(endpoint.matchFulfilled, (state, action) => {
    handleFulfilled(state, action as unknown as PayloadAction<PutCustomersFundingSourcesBankAccountsMicroDeposits['APISuccessResponse'], string, Meta>)
  })
  builder.addMatcher(endpoint.matchRejected, (state, action) => {
    handleRejected(state, action as unknown as PayloadAction<PutCustomersFundingSourcesBankAccountsMicroDeposits['APIErrorResponse']>)
  })
}

export const putCustomersFundingSourcesBankAccountsMicroDepositsRedux = {
  createCases,
  getUrl,
}
