//* This is generated. DO NOT TOUCH
/* eslint-disable */
import { type FetchArgs } from '@reduxjs/toolkit/query'

import { PaymentDueDateChangeRequest} from '@/apis/models/SwaggerTypes'

import { HTTPMethod, type Builder, type MutationThunk, type ErrorResponse, type BaseRTKInterface } from '../../types/apis.types'
import { putAccountsPaymentsDueDateRedux } from './put-accounts-payments-due-date-redux'


export interface APIRequest {
	path: {
		accountId: string
	},

	body: PaymentDueDateChangeRequest
}

type StatusCodes = 401 | 404 | 500
type APIErrorResponse = ErrorResponse<StatusCodes>
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
type APISuccessResponse = void
type APIResponse = APISuccessResponse | APIErrorResponse
const endpointName = 'putAccountsPaymentsDueDate' as const


/**
 * This is the query function for what we want to call.
 * @param request - The ThunkRequest aka HookRequest
 * @returns - The query request
 */
const query = (request: APIRequest): FetchArgs => ({
  url: putAccountsPaymentsDueDateRedux.getUrl(request,),
  method: HTTPMethod.PUT,
	body: request.body,
})


/**
 * This is a function to call if you want to do a mutation (POST, PUT, PATCH)
 * YOU PROBABLY DON'T NEED TO CHANGE THIS FUNCTION
 * @param builder - Creates the thunk for the apis file to be used as a hook
 */
const thunk = (builder: Builder): MutationThunk<APIRequest, APIResponse> => {
  return builder.mutation<APIResponse, APIRequest>({
    query,
  })
}

export interface PutAccountsPaymentsDueDate extends BaseRTKInterface {
  StatusCodes: StatusCodes
  APIErrorResponse: APIErrorResponse
  APISuccessResponse: APISuccessResponse
  APIRequest: APIRequest
  APIResponse: APIResponse
  APISuccessStatusCode: 204
}

export const putAccountsPaymentsDueDate = {
  endpointName,
  thunk
}
